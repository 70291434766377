import React from "react"
import css from '../../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import DaggerIcon from '../../../img/DaggerIcon';
import { getLabel } from '../../us/LdsEntryPage';
import { getNamedNutrients, isSelected } from '../../NfpUtils'

let css2 = {
  nutritionFactPanelSupplemented: {
    maxWidth: '442px'
  },
  av: {
    marginLeft: '1em',
    minHeight: '13px',
    height: '1em'
  },
  bold: {
    fontWeight: 900
  },
  calories: {
    height: '3em'
  },
  caloriesH2: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'right'
  },
  caloriesH3: {
    position: 'absolute', 
    bottom: 0,
    textAlign: 'left'
  },
  colXs6: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    float: 'left',
    width: '48%'
  },
  column1: {
    flexBasis: '100%',
    paddingLeft: 0
  },
  columnNutrientAv: {
    marginLeft: '0.5em',
    fontSize: '11px'
  },
  columnNutrientDv: {
    marginLeft: '0.5em',
    fontSize: '11px',
    float: 'none'
  },
  indent1: {
    padding: '.15em 0 .15em 15px',
  },
  indent1Child: {
    fontWeight: 'normal',
    marginTop: '.3em',
    lineHeight: '1.1em'
  },
  right: {
    textAlign: 'right'
  },
  dv: {
    float: 'right',
    minHeight: '13px',
    height: '1em'
  },
  h1: {
    fontSize: '14pt',
    lineHeight: 1.1,
    verticalAlign: 'bottom',
  },
  h2: {
    fontSize: '13pt',
    lineHeight: 1.1,
    verticalAlign: 'bottom',
  },
  h3: {
    fontSize: '12pt',
    lineHeight: 1.1,
    verticalAlign: 'bottom',
  },
  h4: {
    fontSize: '11pt',
    lineHeight: .9,
    marginTop: '.15em',
    marginBottom: '0',
    verticalAlign: 'bottom',
  },
  h5: {
    fontSize: '10pt',
    lineHeight: .9,
    verticalAlign: 'bottom',
  },
  h6: {
    fontSize: '7.5pt',
    verticalAlign: 'bottom',
  },
  h7: {
    fontSize: '7pt',
    lineHeight: .5,
    verticalAlign: 'bottom',
  },

  m003: {
    marginTop: '0px',
    marginRight: '0px',
    marginBottom: '3px',
    marginLeft: '0px'
  },  
  nutrientName: {
    whiteSpace: 'nowrap',
    fontSize: '11px'
  },
  row: {
    marginTop: '2px'
  },
  servingSize: {
    lineHeight: 1.3
  }
}

export default function NfpSupplementedView ({nfp = {}, lds = {}}) {
  let { microNutrients, supplements } = [...Object.keys(nfp)].reduce((nutrients, id) => {
    let n = nfp[id]
    if(n.isMicroNutrient && isSelected(n)) {
      if(n.isSupplement) {
        nutrients.supplements.push(n)
      }else {
        nutrients.microNutrients.push(n)
      }
    } 
    return nutrients
  }, {microNutrients: [], supplements: []})

  supplements = supplements.sort((a, b) => {
    return a.sequenceSupplemental - b.sequenceSupplemental
  })

  let i = 0
  const { 
    calories, totalFat, satFat, transFat, polyFat, monoFat, cholesterol, sodium, 
    carbs, dietaryFiber, totalSugars, sugarAlcohols, erythritol, protein
  } = getNamedNutrients(nfp)

  return (
    <Box id="nutrition-fact-panel-supplemented" style={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelSupplemented}}>
      <Typography variant="h1" style={{...css.h1, ...css2.h1}}>Supplemented Food Facts</Typography>
      <Typography variant="h1" style={{...css.h1, ...css2.h1, marginBottom: '.25em'}}>Info-aliment supplémenté</Typography>
      <Typography variant="h4" style={{...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline-block', width: 'auto'}}>
        Serving Size {lds.product?.servingSize}
      </Typography>
      <Typography variant="h3" style={{...css.h5, ...css2.h5, fontWeight: 'normal', display: 'inline-block', width: 'auto', marginLeft: '1.5em'}}>
        Portion {lds.product?.servingSize}
      </Typography>
      
      <Divider style={{...css.hr, ...css.hr2}} />
      <Grid container>
        <Grid item size={12}>
          <Box style={{ clear: 'both' }}>
            <Box style={{height: '31px', float: 'left', width: '50%'}}>
              <Typography variant="h3" style={{ ...css.h3 }}>Calories {calories.value ?? '0'}</Typography>
              <Divider style={{ ...css.hr, ...css.hr5 }}/>
            </Box>
            
            <Box style={{...css2.right, float: 'right', width: '50%'}}>
              <Typography variant="h7" style={{ ...css.h7, ...css2.h7, lineHeight: '1.25' }}>% Daily Value*</Typography>
              <br style={{clear: 'both'}}/>
              <Typography variant="h7" style={{ ...css.h7, ...css2.h7, lineHeight: '1.25' }}>% valeur quotidienne*</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <div style={{clear: 'both', height: '2px'}} />
      <Grid container>  
        <Grid item size={12}>
          <Box style={{float: 'left', width: '80%'}}>
            <Typography variant="h4" style={{ ...css.h4, ...css2.h4 }}>Fat / Lipides {totalFat.value || '0'}g</Typography>
          </Box>
          <Box style={{float: 'right', width: '20%'}}>
            <Typography variant="h4" style={{ ...css.h4, ...css2.h4, ...css2.right, margin: 0, padding: 0 }}>{totalFat.percent || '0'}%</Typography>
          </Box>
        </Grid>
      </Grid>
      <div style={{clear: 'both', height: '2px'}} />
      <Grid container>  
        <Grid item size={12}>
          <Box style={css2.indent1}>
            <Grid item size={12} style={{marginTop: '2px'}}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Saturated / saturés</Typography>
              <Box component="span" style={{ ...css.av, ...css2.av }}>{satFat.value ?? '0'}g</Box>
              <Box component="span" style={{ ...css.dv, ...css2.dv }}>{satFat.percent ?? '0'}%</Box>
            </Grid>
            <Grid item size={12} style={{marginTop: '2px'}}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>+ Trans / trans</Typography>
              <Box component="span" style={{ ...css.av, ...css2.av }}>{transFat.value ?? '0'}g</Box>
              <Box component="span" style={{ ...css.dv, ...css2.dv}}>{transFat.percent ?? '0'}%</Box>
            </Grid>
            {isSelected(polyFat) && <Grid item size={12} style={{marginTop: '2px'}}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Polyunsaturated / polyinsaturés</Typography>
              {polyFat.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{polyFat.value ?? '0'}g</Box>}
              {polyFat.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dv}}>{polyFat.percent ?? '0'}%</Box>}
            </Grid>}
            {isSelected(monoFat) && <Grid item size={12} style={{marginTop: '2px'}}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Monounsaturated / monoinsaturés</Typography>
              {monoFat.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{monoFat.value ?? '0'}g</Box>}
              {monoFat.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dv}}>{monoFat.percent ?? '0'}%</Box>}
            </Grid>}
          </Box>
          <Divider style={{ ...css.hr, ...css.hr1 }}/>
        </Grid>
        <Grid item size={12} style={{marginBottom: '.15em'}}>
          <Typography variant="h4" style={{ ...css.h4, ...css2.h4 }}>Carbohydrate / Glucides {carbs.value ?? '0'}g</Typography>
        </Grid>
        <Grid item size={12}>
          <Box style={css2.indent1}>
            <Grid item size={12}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Fibre / Fibres</Typography>
              <Box component="span" style={{ ...css.av, ...css2.av }}>{dietaryFiber.value ?? '0'}g</Box>
              <Box component="span" style={{...css.dv, ...css2.dv}}>{dietaryFiber.percent ?? '0'}%</Box>
            </Grid>
            <Grid item size={12}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Sugars / Sucres</Typography>
              <Box component="span" style={{ ...css.av, ...css2.av }}>{totalSugars.value ?? '0'}g</Box>
              <Box component="span" style={{ ...css.dv, ...css2.dv}}>{totalSugars.percent ?? '0'}%</Box>
            </Grid>
            {isSelected(sugarAlcohols) && <Grid item size={12}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Sugar Alcohols / Polyalcools</Typography>
              {sugarAlcohols.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{sugarAlcohols.value ?? '0'}g</Box>}
              {sugarAlcohols.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dv}}>{sugarAlcohols.percent ?? '0'}%</Box>}
            </Grid>}
            {isSelected(erythritol) && <Grid item size={12}>
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Erythritol / Érythritol</Typography>
              {erythritol.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{erythritol.value ?? '0'}g</Box>}
              {erythritol.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dv}}>{erythritol.percent ?? '0'}%</Box>}
            </Grid>}
          </Box>
          <Divider style={{ ...css.hr, ...css.hr1 }}/>
        </Grid>
        
        <Grid item size={10}>
          <Typography variant="h4" style={{ ...css.h4, ...css2.h4 }}>Protein / Protéines {protein.value ?? '0'}g</Typography>
        </Grid>
        <Divider style={{ ...css.hr, ...css.hr1 }}/>
        
        <Grid item size={10}>
          <Typography variant="h4" style={{ ...css.h4, ...css2.h4 }}>Cholesterol / Cholestérol {cholesterol.value ?? '0'}mg</Typography>
        </Grid>
        <Divider style={{ ...css.hr, ...css.hr1 }}/>
        
        <Grid item size={12}>
          <Typography variant="h4" style={{ ...css.h4, ...css2.h4, display: 'inline' }}>Sodium</Typography>
          <Box component="span" style={{ ...css.av, ...css2.av }}>{sodium.value ?? '0'}mg</Box>
          <Box component="span" style={{...css.dv, ...css2.dv}}>{sodium.percent ?? '0'}%</Box>
        </Grid>        
        
        {microNutrients?.length > 0 && <>
          {microNutrients.map((nutrient, idx) => {
            const item = (
              <React.Fragment key={`nutrient${i}`}>
                <Grid item size={12}><Divider style={{ ...css.hr, ...css.hr1}} /></Grid>
                <Grid item size={12} style={ css2.row }>
                  <Typography variant="h4" style={{ ...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline' }}>{getLabel(nutrient)}</Typography>
                  { nutrient.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{nutrient.value ?? '0'}{nutrient.uom}</Box> }
                  { nutrient.percentState === 'Visible' && <Box component="span" style={{...css.dv, ...css2.dv}}>{nutrient.percent ?? '0'}%</Box>}
                </Grid>
              </React.Fragment>
            )
            i = i + 1
            return item
          })} 
        </>}     


        <Divider style={{ ...css.hr, ...css.hr4 }}/>
        <Grid item size={12} style={{fontSize: '11px'}}>
          <Box component="span">* 5% or less is <Box component="span" style={{fontWeight: 'bold'}}>a little</Box>, 15% or more is <Box component="span" style={{fontWeight: 'bold'}}>a lot</Box></Box><br/>
          <Box component="span">* 5% ou moins c'est <Box component="span" style={{fontWeight: 'bold'}}>peu</Box>, 15% ou plus c'est <Box component="span" style={{fontWeight: 'bold'}}>beaucoup</Box></Box>  
        </Grid>
        <Grid item size={12} style={{fontSize: '11px'}}>
          {supplements?.length > 0 && <>
            <Divider style={{ ...css.hr, ...css.hr4 }}/>
            <Typography variant="h4" style={{ ...css.h4, ...css2.h4, display: 'inline' }}>Supplemented with / Supplémenté en<DaggerIcon style={{fontSize: '9px', height: '9x', width: '9px', position:'relative', top:'-2px'}}/></Typography>
            {supplements.map((nutrient) => {
              const item = (
                <React.Fragment key={`nutrient${i}`}>
                  <Grid item size={12}><Divider style={{ ...css.hr, ...css.hr1}} /></Grid>
                  <Grid item size={12} style={ css2.row }>
                    <Typography variant="h4" style={{ ...css.h4, ...css2.h4, fontWeight: 'normal', display: 'inline' }}>{getLabel(nutrient)}</Typography>
                    { nutrient.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{nutrient.value ?? '0'}{nutrient.uom}</Box> }
                    { nutrient.percentState === 'Visible' && <Box component="span" style={{...css.dv, ...css2.dv}}>{nutrient.percent ?? '0'}%</Box>}
                  </Grid>
                </React.Fragment>
              )
              i = i + 1
              return item
            })}
            <Divider style={{ ...css.hr, ...css.hr1 }}/>
            <Grid item size={12} style={{fontSize: '11px'}}>
              <Box component="span"><DaggerIcon style={{fontSize: '9px', height: '9x', width: '9px', position:'relative', top:'-2px'}}/> Includes naturally occurring and supplemental amounts</Box><br/>
              <Box component="span"><DaggerIcon style={{fontSize: '9px', height: '9x', width: '9px', position:'relative', top:'-2px'}}/> Comprend les quantités naturelles et supplémentées</Box>  
            </Grid>
          </>} 
        </Grid>
        
      </Grid>
    </Box>
  )
}

