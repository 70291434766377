import { useEffect, useMemo, useState } from 'react'
import { ApiGateway } from '../config/config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid2 as Grid, Paper } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { ExpandMore as ExpandMoreIcon, Circle as CircleIcon } from '@mui/icons-material'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import axios from 'axios'
import { getRequestConfig } from '../auth/auth'
import DecimalFormat from '@sei-atl/decimal-format';
import Page from '../layout/Page'
import usFlag from '../img/US.png'
import spFlag from '../img/SP.png'
import frFlag from '../img/FR.png'
let df = new DecimalFormat('#.00000');
let df2 = new DecimalFormat('#.00');

export default function GenesisFormulaViewerPage (props = {}) {
  let navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false);
  const [baseFoodName, setBaseFoodName] = useState({});

  const fetchData = async(id) => {
    setIsLoading(true)
    try {
      const requestConfig = await getRequestConfig()
      const basePath = ApiGateway.genesis
      const response = await axios.get(`${basePath}/base-food-names/${id}`, requestConfig)
  
      setIsLoading(false)
      if(response.status === 200) {
        const result = JSON.parse(response?.data?.body)
        setBaseFoodName(result.data ?? {})
      } else {
        bus.emit(Event.ALERT, {
          text: `Issues Found... Could not retrieve the Recipe with ID ${id}!`,
          style: 'danger'
        });
      }
    } catch (err) {
      setIsLoading(false)
      bus.emit(Event.ALERT, {
        text: `Issues Found... Could not retrieve the Recipe with ID ${id}! ${err}`,
        style: 'danger'
      });
    }
  }

  useEffect(() => {
    fetchData(id)
  }, [id])

  const getCommandBar = () => {
    return <div>
        <Button className="mr-0_5em" onClick={() => {
          navigate('/genesis');
        }}>Back</Button>
      </div>
  }

  const getBreadcrumb = () => {
    let description = baseFoodName?.description || ''
    let parentLink = <></>
    if(baseFoodName?.parent) {
      parentLink = <><Link to={`/genesis/recipes/${baseFoodName.parent.primaryKey}`} onClick={() => fetchData(baseFoodName.parent.primaryKey)}>{baseFoodName.parent.description}</Link> &gt; </>
    }
    return <span><Link to={"/genesis"}><i className="fa fa-cutlery"/> Recipes </Link> &gt; {parentLink}{description}</span>;
  }
  
  const getProp = (n) => {
    let prop = properties.find(p => p.label === n)
    return prop ? <span className="fa fa-check"/> : '??'
  }
  
  let description = baseFoodName?.description ?? ''
  let itemParts = description.split('|')
  let quantity = itemParts.length > 2 ? itemParts[2] : ""
  let measure = itemParts.length > 3 ? itemParts[3] : ""
  let properties = baseFoodName?.properties || []
  let recipeItems = baseFoodName?.recipeItems || []
  let ingredientStatements = baseFoodName?.ingredientStatements || [{ingStmtText: '[None]'}]
  let allergenStatements = baseFoodName?.allergenStatements || [{allergenStatement: '[None]'}]
  let nutrients = baseFoodName?.nutrients || []
  let notes = baseFoodName?.memos || []
  let supplier = baseFoodName?.supplier || {}
  let group = baseFoodName?.group || {}
  let isBatch = baseFoodName?.baseFoodNameType === 'Batch'
  let isServingSize = baseFoodName?.baseFoodNameType === 'ServingSize'
  let recipeItemRows = recipeItems.reduce((acc, recipeItem) => {
    // acc.push({
    //   level: 1, 
    //   name: recipeItem.itemName, 
    //   hasChildren: recipeItem.recipeItems?.length, 
    //   scaledQuantity: recipeItem.quantity, 
    //   uom: isBatch ? 'kg' : 'g'
    // })
    if(recipeItem.recipeItems?.length) {
      const mapChildren = (childItems, level) => {
        childItems.forEach(childItem => {
          acc.push({
            level: level, 
            name: childItem.itemName ,
            hasChildren: childItem.recipeItems?.length, 
            scaledQuantity: childItem.scaledQuantity, 
            uom: isBatch ? 'g' : 'g'
          })
          if(childItem.recipeItems?.length) {
            mapChildren(childItem.recipeItems, level + 1)
          }
  
        })
      }
      mapChildren(recipeItem.recipeItems, 2)
      
    }
    return acc
  }, []) || []

  let propertyRow1 = [{
    itemName: itemParts[0],
    quantity: quantity,
    measure: measure,
    type: baseFoodName.baseFoodNameType === 'ServingSize'  ? 'Serving Size' : baseFoodName.baseFoodNameType,
    supplierName: supplier.supplierName ?? '<None>',
    group: group.name ?? 'Other',   
  }]

  let propertyRow2 = [{
    approvedForCanada: getProp('Approved for Canada'),
    approvedForUs: getProp('Approved for US'),
    bioengineered: getProp('Bioengineered'),
    derivedFromBe: getProp('Derived from BE'),
    fairTrade: getProp('Fair-trade'),
    glutenFree: getProp('Gluten Free'),
  }]

  let propertyRow3 = [{
    gmo: getProp('GMO'),
    kosher: getProp('Kosher'),
    noDna: getProp('No DNA'),
    nonGmo: getProp('Non-GMO'),
    nutrition: getProp('Nutrition'),
    organic: getProp('Organic'),
  }]

  const propertyColumns1 = useMemo(() => [
    {
      header: 'Item Name',
      accessorKey: 'itemName'
    }, {
      header: 'Quantity',
      accessorKey: 'quantity'
    },
    {
      header: 'Measure',
      accessorKey: 'measure'
    },
    {
      header: 'Type',
      accessorKey: 'type'
    }
    ,
    {
      header: 'Supplier',
      accessorKey: 'supplierName'
    }
    ,
    {
      header: 'Group',
      accessorKey: 'group'
    }
    
  ])
  
  const propertiesTable1 = useMaterialReactTable({
    layoutMode: 'grid',
    renderTopToolbar: false,
    enableColumnActions: false,
    enablePagination: false,
    enableSorting: false,
    enableBottomToolbar: false,
    enableEditing: false,
    columns: propertyColumns1,
    data: propertyRow1,
    enablePagination: false,
  })

  const propertyColumns2 = useMemo(() => [
    {
      header: 'Approved for Canada',
      accessorKey: 'approvedForCanada'
    },
    {
      header: 'Approved for US',
      accessorKey: 'approvedForUs'
    },
    {
      header: 'Bioengineered',
      accessorKey: 'bioengineered'
    },
    {
      header: 'Derived from BE',
      accessorKey: 'derivedFromBe'
    },
    {
      header: 'Fair-trade',
      accessorKey: 'fairTrade'
    },
    {
      header: 'Gluten Free',
      accessorKey: 'glutenFree'
    },
    
  ])
  const propertiesTable2 = useMaterialReactTable({
    layoutMode: 'grid',
    renderTopToolbar: false,
    enableColumnActions: false,
    enablePagination: false,
    enableSorting: false,
    enableBottomToolbar: false,
    enableEditing: false,
    columns: propertyColumns2,
    data: propertyRow2,
    enablePagination: false,
  })

  const propertyColumns3 = useMemo(() => [
    {
      header: 'GMO',
      accessorKey: 'gmo'
    },
    {
      header: 'Kosher',
      accessorKey: 'kosher'
    },
    {
      header: 'No DNA',
      accessorKey: 'noDna'
    },
    {
      header: 'Non-GMO',
      accessorKey: 'nonGmo'
    },
    {
      header: 'Nutrition',
      accessorKey: 'nutrition'
    },
    {
      header: 'Organic',
      accessorKey: 'organic'
    }
  ])

  const propertiesTable3 = useMaterialReactTable({
    layoutMode: 'grid',
    renderTopToolbar: false,
    enableColumnActions: false,
    enablePagination: false,
    enableSorting: false,
    enableBottomToolbar: false,
    enableEditing: false,
    columns: propertyColumns3,
    data: propertyRow3,
    enablePagination: false,
  })

  const ingredientColumns = useMemo(() => [
    {
      header: 'Name',
      enableSorting: false,
      Cell: (rowInfo) => {
        let level = rowInfo?.row?.original?.level
        let label = rowInfo?.row?.original?.name
        if(level === 1) {
          // if(rowInfo?.row?.original?.hasChildren) {
          //   return <div style={{ paddingLeft: '1.5em'}}><ExpandMoreIcon sx={{marginRight: '1em'}} /> {label}</div>
          // } else {
          //   return <div style={{ paddingLeft: '2.25em'}}>{label}</div>
          // }
        } else {
          if(rowInfo?.row?.original?.hasChildren) {
            return <div style={{ paddingLeft: `${1.5*(rowInfo?.row?.original?.level ?? 2)}em`}}><ExpandMoreIcon sx={{marginRight: '1em'}} /> {label}</div>
          } else {
            if(level === 2) {
              return <div style={{ paddingLeft: `${1.5*(rowInfo?.row?.original?.level ?? 2) + 1}em`}}>{label}</div>
            } else {
              return <div style={{ paddingLeft: `${1.5*(rowInfo?.row?.original?.level ?? 2) + 1}em`}}><CircleIcon sx={{fontSize: '6px', marginRight: '2em'}} /> {label}</div>
            }
          }
        }
      }
    },
    {
      header: () => isBatch ? 'Weight (g)' : 'Weight (g)',
      accessorKey: 'scaledQuantity',
      enableSorting: false,
      className: 'right', // Right-align cells
      Cell: (rowInfo) => {
        let level = rowInfo?.row?.original?.level
        let value = rowInfo?.row?.original?.scaledQuantity
        let label = `${df.format(value)} ${rowInfo?.row?.original?.uom}`
        if(level === 1) {
          return <div style={{ marginRight: '65%'}} title={value}>{label}</div>
        } else {
          return <div style={{ marginRight: '55%'}} title={value}>{label}</div>
        }
      }
    }
  ])

  const ingredientsTable = useMaterialReactTable({    
    columns: ingredientColumns,
    data: recipeItemRows,
    renderTopToolbar: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enablePagination: false,
    enableHiding: false,
    enableDensityToggle: false,
  })

  const nutrientColumns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'name',
      size: 50
    },{
      header: 'Weight',
      accessorKey: 'weight',
      muiTableBodyCellProps: {
        sx: { textAlign: 'right', paddingRight: '7.5em' },
      },
      Cell: (rowInfo) => {
        let label = df2.format(rowInfo.renderedCellValue)
        return <div title={rowInfo.renderedCellValue}>{label}</div>
      },
      size: 50
    },{
      header: 'Rounded Weight',
      accessorKey: 'roundedWeight',
      muiTableBodyCellProps: {
        sx: { textAlign: 'right', paddingRight: '7.5em' },
      },
      Cell: (rowInfo) => {
        let label = df2.format(rowInfo.renderedCellValue)
        return <div title={rowInfo.renderedCellValue}>{label}</div>
      },
      size: 50
    },{
      header: 'Rounded %DV',
      accessorKey: 'roundedDailyValue',
      muiTableBodyCellProps: {
        sx: { textAlign: 'right', paddingRight: '7.5em' },
      },
      Cell: (rowInfo) => {
        let label = df2.format(rowInfo.renderedCellValue)
        return <div title={rowInfo.renderedCellValue}>{label}</div>
      },
      size: 50
    },{
      header: 'Units',
      accessorKey: 'unitOfMeasure',
      size: 50
    }
  ])

  const nutrientsTable = useMaterialReactTable({
    columns: nutrientColumns,
    data: nutrients,
    enableGlobalFilter: true,
    enableColumnActions: false,
    enablePagination: false,
    enableHiding: false,
    enableDensityToggle: false,
    initialState: {showGlobalFilter: true}
  })

  return (
    <Page { ...props} breadcrumb = {getBreadcrumb()} commandBar={getCommandBar()} isLoading={isLoading}>
      <Paper sx={{padding: '.5em 1em 3em'}}>
        <Box sx={{backgroundColor: '#EFEFEF', padding: '.5em 1em 1em', boxShadow: '5px 10px 15px rgba(0, 0, 0, 0.3)', borderRadius: '4px', border: '1px solid #ccc'}}>
          <h2>Properties</h2>
          <MaterialReactTable table={propertiesTable1} style={{ width: 'auto', margin: '10px' }}/>
          <br/>
          <MaterialReactTable table={propertiesTable2} style={{ width: 'auto', margin: '10px' }}/>
          <br/>
          <MaterialReactTable table={propertiesTable3} style={{ width: 'auto', margin: '10px' }}/>
        </Box>  
        <br/>
        <Grid container>
          <Grid size={isServingSize ? 5.75 : 12}>
            <Box>
              <h2>Ingredients</h2>
              <MaterialReactTable table={ingredientsTable} />
            </Box>
          </Grid>
          {isServingSize && 
            <>
              <Grid size={.25}></Grid>
              <Grid size={6}>
                <Box>
                  <h2>Nutrients</h2>
                  <MaterialReactTable table={nutrientsTable} />
                </Box>
              </Grid>
            </>
          }
          <Grid size={5.75}>
            <br/>
            <Box sx={{backgroundColor: '#EFEFEF', padding: '.5em 1em', boxShadow: '5px 10px 15px rgba(0, 0, 0, 0.3)', borderRadius: '4px', border: '1px solid #ccc'}}>
              <h2>Ingredient Statements</h2>
              {ingredientStatements.map(stmt => {
                let flag = <></>
                switch (stmt.languageEnum) {
                  case 1:
                    flag = <img style={{marginRight: '1em'}} className="inline-image" src={usFlag}/>
                    break;
                  case 2:
                    flag = <img style={{marginRight: '1em'}} className="inline-image" src={frFlag}/>
                    break;
                  case 3:
                    flag = <img style={{marginRight: '1em'}} className="inline-image" src={spFlag}/>
                    break
                }
                
                return <div style={{marginTop: '2em'}}>{flag} {stmt.ingStmtText}</div>
              })}
            </Box>
          </Grid>
          <Grid size={.25}></Grid>
          <Grid size={6}>
            <br/>
            <Box sx={{backgroundColor: '#EFEFEF', padding: '.5em 1em', boxShadow: '5px 10px 15px rgba(0, 0, 0, 0.3)', borderRadius: '4px', border: '1px solid #ccc'}}>
              <h2>Allergen Statements</h2>
              {allergenStatements.map(stmt => {
                let flag = <></>
                switch (stmt.languageEnum) {
                  case 1:
                    flag = <img style={{marginRight: '1em'}} className="inline-image" src={usFlag}/>
                    break;
                  case 2:
                    flag = <img style={{marginRight: '1em'}} className="inline-image" src={frFlag}/>
                    break;
                  case 3:
                    flag = <img style={{marginRight: '1em'}} className="inline-image" src={spFlag}/>
                    break
                }
                  return <div style={{marginTop: '2em'}}>{flag} {stmt.allergenStatement}</div>
                })}
            </Box>
          </Grid>            
          
        </Grid>  
        <br/>     
        <Box>
          <h2>Notes</h2>
          {notes.map(note => <div style={{marginTop: '2em'}}>{note.memo}</div>)}
        </Box> 
      </Paper>
    </Page>
  )
}
