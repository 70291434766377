import React, { useEffect, useState } from 'react';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DaggerIcon from '../img/DaggerIcon';

const getNextState = (currentState, showCitation, isMandatory=false) => {
  
  if (showCitation) {
    if(isMandatory) {
      return currentState === 'Visible' ? 'Citation' : 'Visible';
    } else {
      switch (currentState) {
        case 'Visible':
          return 'Hidden';
        case 'Hidden':
          return 'Citation';
        case 'Citation':
          return 'Visible';
        default:
          return 'Visible';
      }
    }
  } else {
    return currentState === 'Visible' ? 'Hidden' : 'Visible';
  }
};

const LDSInput = (props) => {
  let { showToggles, showCitation, nfp = {}, id, label, value, checkState, onChange, ...otherProps } = props;

  const [labelSize, setLabelSize] = useState(props.labelSize ?? 7); // initial state
  const [inputSize, setInputSize] = useState(); // initial state
  const [currentState, setCurrentState] = useState(checkState); // initial state

  const handleIconClick = () => {
    const nextState = getNextState(currentState, showCitation, nfp?.isMandatory);
    setCurrentState(nextState);
    if (onChange) {
      const synthEvent = {
        target: {
          id: `${id}Check`,
          value: nextState
        }
      };
      // console.log('LDSInput.onChange, firing event: ', synthEvent)
      onChange(synthEvent);
    }
  };

  const renderIcon = () => {
    switch (currentState) {
      case 'Visible':
        return <Visibility onClick={handleIconClick} />;
      case 'Hidden':
        return <VisibilityOff onClick={handleIconClick} />;
      case 'Citation':
        return <DaggerIcon onClick={handleIconClick} />;
      default:
        return <Visibility onClick={handleIconClick} />;
    }
  };

  useEffect(() => {
    if(labelSize || inputSize) {
      if(labelSize) {
        setLabelSize(labelSize)
        setInputSize(inputSize ?? (12-labelSize))
      } else if(inputSize) {
        setInputSize(inputSize)
        setLabelSize(labelSize ?? (12-inputSize))
      }
    }
  }, [labelSize, inputSize])

  const processedLabel = label.replace(/Vitamin B(\d*)/g, (_, p1) => {
    return `Vitamin B<sub>${p1}</sub>`;
  });

  return (
    <Grid container>
      <Grid item size={labelSize} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Typography sx={{ textAlign:'right', marginRight: '1em' }}>
          <span dangerouslySetInnerHTML={{ __html: processedLabel }} title={nfp.id}/>
        </Typography>
      </Grid>
      <Grid item size={inputSize ?? 5}>
        <Box
          className="lds-checkbox shadow"
          id={`${id}Check`}
          sx={{
            width: '70%',
            minHeight: '43px',
            border: '1px solid #999',
            borderRadius: '4px',
            margin: '0 1em',
            padding: '.5em 1em',
            backgroundColor: currentState === 'Hidden' ? '#F3F3F3' : '#DDFFEE',
            position: 'relative',
          }}
          {...otherProps}
        >
          <Typography sx={{ display: 'inline' }}>{value}</Typography>
          <Box
            sx={{ position: 'absolute', right: '1em', top: '.4em', cursor: 'pointer' }}
          >
            {showToggles && renderIcon()}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LDSInput;