import { sraDomain, domain, azureClientId, azureTenantId, apiKey, stage, featureFlags } from './config-current'

export const Stage = stage
export const FeatureFlags = featureFlags
export const ApiGateway =  {
  apiKey,
  sraDomain,
  domain,
  formulaNutrition: `${domain}/formula-nutritions`,
  dispensingHardwareType: `${domain}/dispensing-hardware-types`,
  documentGeneration: `${domain}/document-generation`,
  employee: `${domain}/employees`,
  labelDeclarationSheets: `${domain}/lds-service`,
  profile: `${sraDomain}/profile`,
  user: `${sraDomain}/users`,
  genesis: `${domain}/genesis`,
  auth: ''
}

export const AzureAdClient = {
  info: {
    clientId: `${azureClientId}`,
    tenantId: `${azureTenantId}`
  },
  auth: {
    clientId: `${azureClientId}`,
    authority: `https://login.microsoftonline.com/${azureTenantId}`
  }
}
