import { useEffect, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid2 as Grid, Paper } from '@mui/material'

export default function ListNutritionCalculations({data = [], countryId, numberFormat, allNutrientsShown, servingSizeUnit='fl-oz'}) {
  
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnData, setColumnData] = useState([])

  const mapData = (data) => {
    const locale =  (countryId === 124) ? 'fr-CA' : 'en-US'
    if(data?.length) {
      return data.map(d => {
        return allNutrientsShown.reduce((newData, nutrient) => {
          let value = numberFormat ? numberFormat.format(d[nutrient]) : d[nutrient]
          newData[nutrient] = <span title={d[nutrient]}>{value}</span>
          return newData
        }, {
          dispensingHardware: d['Dispensing Hardware'],
          productName: d['Product Name'],
          sapBeverageId : d['SAP Beverage ID'],
          size: d.Size,
          servingSizeUnit: d[`(${servingSizeUnit})`],
          bibRatio: d['BIB Ratio'],
          allergens: d.Allergens?.[locale] ?? '',
          claims: d.Claims?.[locale] ?? '',
          fortifiedWith: d['Fortified With']?.[locale] ?? '',
          ingredients: d.Ingredients?.[locale] ?? ''
        })
      })
    }
    return []
  }

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            height: '150px', // Adjust the height based on your requirements
            textAlign: 'right',
            verticalAlign: 'bottom',
            borderRight: '1px solid rgb(224, 224, 224)',
            backgroundColor: '#CCC !important',
            color: '#333 !important',
            // maxWidth: '40px'
          },
          root: {
            textAlign: 'right',
            borderRight: '1px solid rgb(224, 224, 224)',
            padding: '2px',
            boxSizing: 'border-box', // Necessary to calculate width including padding
            textOverflow: 'ellipsis', // Ensure text overflow is handled
            overflow: 'hidden', // Hide overflow content
            whiteSpace: 'nowrap', // Prevent content from wrapping
            maxWidth: 'inherit', // Ensure maxWidth set on columns is respected
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {

            '&:nth-of-type(even)': {
              backgroundColor: '#f2f2f2 !important',  // Apply a light grey background to even rows
            },
            '&:hover': {
              backgroundColor: '#b3e5fc !important', // Set the hover color
            },
          },
        },
      },
    },
  });

  const renderVerticalHeader = (label) => (
    <Box
      component="div"
      sx={{
        transform: 'rotate(-90deg)',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding:0,
        overflow:'visible',
        width: '40px',
        height: '155px', // Adjust the height based on your requirements
      }}
    >
      {label}
    </Box>
  );

  useEffect(() =>  {
    console.log('data: ', data)
    console.log('allNutrientsShown: ', allNutrientsShown)
    if(data?.length && allNutrientsShown?.length) {
      setColumnData(mapData(data))
      let nutrientColumns = allNutrientsShown.map(nutrient => {
        return {
          header: renderVerticalHeader(nutrient),
          accessorKey: nutrient,
          maxSize: 25,
        }
      })
      if(nutrientColumns?.length) {
        const listColumns = [
          {
            header: renderVerticalHeader('Dispensing HW'),
            accessorKey: 'dispensingHardware',
            size:150,
            maxSize: 150
          },
          {
            header: renderVerticalHeader('Product Name'),
            accessorKey: 'productName',
            maxSize: 180
          }, {
            header: renderVerticalHeader('SAP Beverage ID'),
            accessorKey: 'sapBeverageId',
            enableSorting: false, 
            maxSize: 25
          }, {
            header: renderVerticalHeader('Size'),
            accessorKey: 'size',
            maxSize: 25
          }, {
            header: renderVerticalHeader(('' + servingSizeUnit).replace('-', '')),
            accessorKey: 'servingSizeUnit',
            maxSize: 25,
          }, {
            header: renderVerticalHeader('BIB Ratio'),
            accessorKey: 'bibRatio',
            maxSize: 25,
          }, 
          ...nutrientColumns,
          {
            header: renderVerticalHeader('Allergens'),
            accessorKey: 'allergens',
            maxSize: 50,
          }, 
          {
            header: renderVerticalHeader('Claims'),
            accessorKey: 'claims',
            maxSize: 25,
          }, 
          {
            header: renderVerticalHeader('Fortified With'),
            accessorKey: 'fortifiedWith',
            maxSize: 25,
          }, 
          {
            header: renderVerticalHeader('Ingredients'),
            accessorKey: 'ingredients',
            minSize: 500,
          }
        ]
        // listColumnOrder = ['Dispensing HW', 'Product Name', 'SAP Beverage ID', `(${servingSizeUnit})`, 'BIB Ratio',...(nutrientColumns.map(c => c.accessorKey)), 'Allergens', 'Claims', 'Fortified With', 'Ingredients']

        setColumns(listColumns)
      }
    }
    // setColumnOrder(listColumnOrder)
  }, [allNutrientsShown, data, countryId])


  const table = useMaterialReactTable({
    columns: columns ?? [],
    data: columnData ?? [],
    paginationDisplayMode: 'pages',
    enableColumnOrdering: false, // Disable column reordering
    enableFullScreenToggle: false, // Disable full screen option
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnActions: false,
    // initialState: {
    //   showColumnFilters: false,
    // },
    // renderTopToolbar: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    state: {
      isLoading,
      showAlertBanner: isError,
    },
  });
  
  return (
    <Paper>
      <Grid container rowSpacing={2} >
        <Grid size={12}>      
          <ThemeProvider theme={theme}>      
            {columnData?.length && <MaterialReactTable 
              table={table} 
            />}
          </ThemeProvider>
        </Grid>
      </Grid>
    </Paper>
  )
}