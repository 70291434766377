import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { getRequestConfig } from '../../auth/auth.js'
import RoleContext from '../../auth/RoleContext.js'
import { ROLES } from '../../auth/role'
import NfpVerticalView from "./nfpViews/NfpVerticalView.js"
import NfpTabularView from './nfpViews/NfpTabularView.js'
import NfpLinearView from './nfpViews/NfpLinearView.js'
import NfpDualColumnView from './nfpViews/NfpDualColumnView.js'
import AmericanFlagIcon from '../../img/flag-usa'
import axios from 'axios'
import Page from '../../layout/Page.js'
import he from 'he'
import Event from '../../utils/EventNames.js'
import { default as bus } from '../../utils/EventBus.js'
import { Tab, Tabs, TabPanel} from '../../layout/Tabs.js'
import LDSInput from '../../form/LDSInput.js'
import { HorizontalRule } from '../../layout/HorizontalRule.js'
import { handleFieldChange, handleCheckboxChange, handleSelectChange } from '../../form/Form.js'
import { Article as ArticleIcon, Download as DownloadIcon } from '@mui/icons-material'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, MenuItem, Paper, Select, Typography } from '@mui/material'
import Panel from "../../layout/Panel.js";
import { getNamedNutrients } from '../NfpUtils'
import { ApiGateway } from '../../config/config.js'
import b64toBlob from 'b64-to-blob'
import moment from 'moment'
const basePath = ApiGateway.labelDeclarationSheets

export const getLabel = (s, isAbbreviated) => {
  let label = isAbbreviated && s?.abbreviation ? s.abbreviation : s?.label
  if(/\b([tT])rans\b/.test(label)) {
    let text = ('' + label).replace(/\b([tT])rans\b/, (_, match) => `<i>${match}rans</i>`)
    label = <span dangerouslySetInnerHTML={{__html: text}}/>
  }
  if(/Vitamin B(\d*)/.test('' + label)) {
    let text = ('' + label).replace('/Vitamin B(\d*)/', (_, match) => `Vitamin B<sub>${match}</sub>`)
    label = <span dangerouslySetInnerHTML={{__html: text}}/>
  }
  return label
}

export default function LdsEntryPage (props) {
    // const theme = useTheme()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false)

    const ldsDefaults = {status: 'draft', layout: 'vertical', isSimplified: false, isSimplifiedWithSugar: false, isAbbreviated: false, serve: 'single'}
    // const [nfp, setNfp] = useState(nfpDefaults);
    const [lds, setLds] = useState(ldsDefaults);
    const [ldsInfo, setLdsInfo] = useState({});
    const [nfp1, setNfp1] = useState({});
    const [nfp2, setNfp2] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [significantSourceText, setSignificantSourceText] = useState();
    const { id } = useParams()

    const setNfp1AmountState = (value) => {
      let newNfp1 = structuredClone(nfp1)
      let keys = Object.keys(value)
      let id = keys.length ? keys[0] : undefined
      if(newNfp1[id]) {
        newNfp1[id].amountState = value[id]
        setNfp1(newNfp1)
      }
    }

    const setNfp1PercentState = (value) => {
      let newNfp1 = structuredClone(nfp1)
      let keys = Object.keys(value)
      let id = keys.length ? keys[0] : undefined
      if(newNfp1[id]) {
        newNfp1[id].percentState = value[id]
        setNfp1(newNfp1)
      }
    }

    const setLdsIsSimplified = (newLds) => {
      if(!newLds.isSimplified) {
        newLds.isSimplifiedWithSugar = false
      }
      setLds(newLds)
    }

    const setDirty = () => {
      setIsDirty(true)
    }

    useEffect(() => {
        (async() => {
            setIsLoading(true)
            const requestConfig = await getRequestConfig()
            
            const ldsResponse = await axios.get(`${basePath}/products/${id}/nfp`, requestConfig)
            // console.log('ldsResponse: ', ldsResponse)
            if(ldsResponse?.status === 200) {
                // console.log('nfpResponse.data: ', nfpResponse.data)

                let newLds = ldsResponse.data?.data ?? {}
                newLds.status = (newLds.status ?? '').trim().length > 0 ? newLds.status : 'draft'
                newLds.layout = (newLds.layout ?? '').trim().length > 0 ? newLds.layout : 'vertical'
                newLds.isSimplified = newLds.isSimplified ?? false
                newLds.isSimplifiedWithSugar = newLds.isSimplifiedWithSugar ?? false
                newLds.isAbbreviated = newLds.isAbbreviated ?? false
                newLds.serve = newLds.serve !== undefined && ('' + newLds.serve).trim().length > 0 ? newLds.serve : 'single'
                setLds(newLds)
                const mapNutrientInfo = (nutrientInfos) => {
                  if(nutrientInfos?.length) {
                    return nutrientInfos.reduce((nfp, nutrientInfo, idx) => {
                      let { nutrient } = nutrientInfo
                      let value = nutrientInfo.byServing?.roundedValue ?? 0
                      let percent = nutrientInfo.byServing?.roundedDailyValue ?? 0
                      let isMandatory = nutrientInfo.isMandatory
                      let showAmount = nutrientInfo.showAmount ?? true
                      let showPercent = nutrientInfo.showPercent ?? true

                      let amountState = nutrientInfo.amountState
                      if(showAmount && !amountState) {
                        amountState = nutrientInfo.isMandatory ? 'Visible' : value > 0 ? 'Visible' : 'Hidden'
                      }
                      let percentState = nutrientInfo.percentState
                      if(showPercent && !percentState) {
                        percentState = nutrientInfo.isMandatory ? 'Visible' : percent > 0 ? 'Visible' : 'Hidden'
                      }
                      const isMicroNutrient = nutrientInfo.nutrient?.isMicroNutrient
                      

                      nfp[nutrient.id] = {
                        id: nutrient.id,
                        label: nutrient.name,
                        abbreviation: nutrient.abbreviation,
                        value,
                        amountState,
                        percent,
                        percentState,
                        isMandatory,
                        showAmount,
                        showPercent,
                        isMicroNutrient,
                        uom: nutrientInfo?.nutrient?.uom?.abbreviation
                      }
                      
                      return nfp
                    }, {})
                  }
                }

                if(newLds.nfp1) {
                  let { nutrientInfos } = newLds.nfp1
                  let newNfp1 = mapNutrientInfo(nutrientInfos)
                  setNfp1(newNfp1)
                }

                if(newLds.nfp2) {
                  let { nutrientInfos } = newLds.nfp2
                  let newNfp2 = mapNutrientInfo(nutrientInfos)
                  setNfp2(newNfp2)
                }
                
                const ldsInfoResponse = await axios.get(`${basePath}/products/${id}/lds-information`, requestConfig)
                if(ldsInfoResponse.status === 200) {
                    // console.log('ldsInfoResponse.data: ', ldsInfoResponse.data)
                    setLdsInfo(ldsInfoResponse.data?.data)
                }
            }
            setIsLoading(false)
        })()
    }, [id])

    useEffect(() => {
      // setNutrientList(getNutrientList(nfp.nfp1, nfp.nfp2))
      if(nfp1 || nfp2) {
        let text = (activeTab === 0) ? getSignificantSourceText(nfp1) : getSignificantSourceText(nfp2)
        // console.log('sig: ', text)
        setSignificantSourceText(text)
      }
    }, [lds, nfp1, nfp2])

    const getSignificantSourceText = (nfp = {}) => {
      // console.log('getSignificantSourceText, nfp: ', nfp)
      
      const { 
        satFat, transFat, polyFat, monoFat, cholesterol, dietaryFiber, totalSugars, addedSugars, vitaminD, calcium, iron, potassium
      } = getNamedNutrients(nfp)

      //All these nutrients are insignificant if less than 2% of DV
      const sources1 = [ satFat, transFat, polyFat, monoFat, cholesterol, dietaryFiber, totalSugars ]
      const sources2 = [ vitaminD, calcium, iron, potassium ]

      let insignificantSources = sources1.filter(s => (parseFloat(s?.percent)) < 2)

      if(!lds.isSimplifiedWithSugar) {
        sources2.unshift(addedSugars)
        //Total sugars is insignificant if less than 1g
        if(!totalSugars?.value || totalSugars?.value < 1) { 
          insignificantSources.push(totalSugars)
        }
      }
      let insignificantSources2 = sources2.filter(s => (parseFloat(s?.percent)) < 2)
      if(insignificantSources2?.length) {
        insignificantSources = [...insignificantSources, ...insignificantSources2]
      }

      let text = []
      if(insignificantSources.length > 0) {
        if(insignificantSources.length > 2) {
          let last = insignificantSources.pop()
          text = insignificantSources.map(s => {
            let label = getLabel(s, lds.isAbbreviated)
            return <>
            {label},
            </>
          })
          text.push(<><span> and </span>{getLabel(last, lds.isAbbreviated)}</>)
          
        } else if (insignificantSources.length > 1) {
          text = [getLabel(insignificantSources[0], lds.isAbbreviated), <span> and </span>, getLabel(insignificantSources[1], lds.isAbbreviated)]
        } else {
          text = [getLabel(insignificantSources[0], lds.isAbbreviated)]
        }
        text.unshift(<span>Not a significant source of </span>)
      }
      
      return <>{text.map((item, idx) => (
        <React.Fragment key={idx}>{item}</React.Fragment>
      ))}</>
    }


  const getBreadcrumb = () => {
    let breadcrumb = <span><ArticleIcon /> Label Declaration Sheet Entry <AmericanFlagIcon/></span>;
    return breadcrumb;
  }

  const getCommandBar = () => {
    let commandBar;
    commandBar =
    <RoleContext.Consumer>
      { roleCtx => (
      <div>
        {!isReadOnly && <Button size="small" variant="contained" sx={{marginRight:'.5em'}} disabled={!isDirty || isSaving} onClick={async (event) => {
          await save(event)
        }}>{lds?.id ? 'Save' : 'Create'}</Button>}
        <Button size="small" variant="contained" sx={{marginRight:'.5em'}} onClick={() => {
          if (isDirty) {
            setShowCancelModal(true)
          } else {
            navigate(-1);
          }
        }}>Cancel</Button>
        {/* { roleCtx.hasRole([ROLES.ADMIN, ROLES.SRA_ADMIN]) && */}
        <Button size="small" variant="contained" sx={{marginRight:'.5em'}} onClick={(event) => {
          downloadWord(event);
        }}><DownloadIcon /> Download</Button> 
        {/*<Button bsStyle="primary" disabled={true} onClick={(event) => {
          this.downloadWord(event, false, true);
        }}><i className="fa fa-file-pdf-o" /> PDF</Button>*/}
      </div>
      )}
    </RoleContext.Consumer>

    return commandBar;
  }

  const downloadWord = async (event, getWord = true, getPdf = false) => {
    event.preventDefault();
    
    if(lds) {
      setIsLoading(true)
      let templateData = {
        ldsStatus: lds.status,
        productName: lds.product?.name,
        productReason: ldsInfo.reasonForIssuance ?? '',
        productSoi: ldsInfo.statementOfIdentity ?? '',
        productNetQuantity: ldsInfo.netQuantityOfContentsFreeForm ?? '',
        productFrontOfPack: ldsInfo.frontOfPackDeclarationFreeForm ?? '',
        productJuiceStatement: ldsInfo.juiceContentFinalValue ?? '',
        productIngredientStatement: ldsInfo.ingredients ?? '',
        productManufacturerInfo: ldsInfo.finalManufacturersStatement ?? '',
        productCountryOfOrigin: ldsInfo.countryOfOriginStatement ?? '',
        productAllergenStatement: ldsInfo.allergensNotes ?? '',
        productBioengineeredStatement: ldsInfo.bioengineeredDisclosureStatement ?? '',
        productNutrientContentClaim: ldsInfo.nutrientContentHealthClaimFreeForm ? (he.decode(ldsInfo.nutrientContentHealthClaimFreeForm.replace(/(<([^>]+)>)/gi, ""))) : '',  
        productQualifyingLanguage: ldsInfo.otherInformationClaimsFreeForm ? (he.decode(ldsInfo.otherInformationClaimsFreeForm.replace(/(<([^>]+)>)/gi, ""))) : '',
        productOtherClaims: ldsInfo.otherInformation ?? '',
        productBlendStatement: ldsInfo.blendStatement ?? '',
        productCaffeineInformation: ldsInfo.caffeineQuantitativeClaimFreeForm ?? '',
        productOrganicInformation: ldsInfo.organicInformation ? `(Organic Information): ${ldsInfo.organicInformation}` : '',
        productReconstitutionInstructions: ldsInfo.reconstitutionInstructions ?? '',
        productStorageUseInstructions: ldsInfo.storageUseInstructions ?? '',
        productPkuWarningStatement: ldsInfo.pkuWarningStatement ?? '',
        productConsumerInquiries: ldsInfo.consumerInquiriesFreeForm ?? '',
        productKosher: ldsInfo.kosherFreeForm ?? '',
        productLabelCode: ldsInfo.labelCode ?? '',
        productBbn: lds.bbn,
        issueDate: ldsInfo.productCreatedDate ? moment(ldsInfo.productCreatedDate).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
        revisedDate: lds.updatedAt ? moment(lds.updatedAt).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
        replaceBbn: ldsInfo.replaceBbn ?? ''
      }
      templateData.productNutrientContentClaim = ('' + templateData.productNutrientContentClaim).endsWith('.') ? templateData.productNutrientContentClaim : ('' + templateData.productNutrientContentClaim).length > 0 ? templateData.productNutrientContentClaim + '.' : ''
      templateData.productQualifyingLanguage = ('' + templateData.productQualifyingLanguage).endsWith('.') ? templateData.productQualifyingLanguage : ('' + templateData.productQualifyingLanguage).length > 0 ? templateData.productQualifyingLanguage + '.' : ''

      let inputFileName = 'LabelDeclarationSheet.docx';
      let nfpView = {}
      if(lds.layout === 'vertical') {
        nfpView = document.getElementById('nutrition-fact-panel-vertical')
        nfpView = `<html><head><style>body { width: 400px; }</style></head><body>${nfpView.outerHTML}</body></html>`
      }
      if(lds.layout === 'tabular') {
        inputFileName = 'LabelDeclarationSheetLandscape.docx';
        nfpView = document.getElementById('nutrition-fact-panel-tabular')
        nfpView = `<html><head><style>body { width: 800px; }</style></head><body>${nfpView.outerHTML}</body></html>`
      }
      if(lds.layout === 'linear') {
        nfpView = document.getElementById('nutrition-fact-panel-linear')
        nfpView = `<html><head><style>body { width: 400px; }</style></head><body>${nfpView.outerHTML}</body></html>`
      }
      if(lds.layout === 'dual') {
        nfpView = document.getElementById('nutrition-fact-panel-dual-column')
        nfpView = `<html><head><style>body { width: 400px; }</style></head><body>${nfpView.outerHTML}</body></html>`
      }

      templateData.nfp = nfpView

      let body = {
        convertToImage:['nfp'],
        data: templateData,
        template: inputFileName
      }
      const requestConfig = await getRequestConfig()
      const response = await axios.post(`${ApiGateway.documentGeneration}/docxFromTemplate?fullPage=false`, body, requestConfig)
      if (response && response.data) {
        // console.log('response: ', response);
        let blob = b64toBlob(response.data, 'binary/octet-stream');
        let fileName = 'label-declaration-sheet.docx';
        if (getWord) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // If IE, you must uses a different method.
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.style = 'display:none';
            document.body.appendChild(a);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }
        }
        
      }
      setIsLoading(false)
    }
  }

  const save = async(event) => {
    if(nfp1) {
      let nfp1Ids = [...Object.keys(nfp1)]
      if(nfp1Ids.length > 0) {
        setIsSaving(true)
        const requestConfig = await getRequestConfig()
        let newLds = {
          id: lds.id,
          status: lds.status,
          isSimplified: lds.isSimplified,
          isSimplifiedWithSugar: lds.isSimplifiedWithSugar,
          layout: lds.layout,
          serve: lds.serve,
          isAbbreviated: lds.isAbbreviated,
          version: lds.version,
          product: {
            id: lds.product?.id
          },
          nfp1: {
            nutrientInfos: nfp1Ids.map(id => {
              return {
                nutrient: { id, name: nfp1[id].label },
                amountState: nfp1[id].amountState,
                percentState: nfp1[id].percentState,
                isSupplement: nfp1[id].isSupplement
              }
            })
          }
        }
      
        if(nfp2) {
          let nfp2Ids = [...Object.keys(nfp2)]
          if(nfp2Ids.length > 0) {
            newLds.nfp2 = {
              nutrientInfos: nfp2Ids.map(id => {
                return {
                  nutrient: { id, name: nfp2[id].label },
                  amountState: nfp2[id].amountState,
                  percentState: nfp2[id].percentState,
                  isSupplement: nfp2[id].isSupplement
                }
              })
            }
          }
        }
        let response
        if(newLds.id) {
          response = await axios.put(`${basePath}/lds/${newLds.id}`, newLds, requestConfig)
        } else {
          response = await axios.post(`${basePath}/lds`, newLds, requestConfig)
        }
        if(response.status === 200) {
          let savedLds = structuredClone(lds)
          savedLds.version = response.data?.version
          savedLds.id = response.data?.id
          setLds(savedLds)
          setIsDirty(false)
          bus.emit(Event.ALERT, {
            text: 'Saved Successfully!',
            style: 'success',
            duration: 2000
          })
        } else {
          bus.emit(Event.ALERT, {
            text: 'There was an issue saving... please try again later',
            style: 'error'
          })
        }
        setIsSaving(false)
      }
    }
  }

  const handleTabChange = async (_event, activeTab) => {
    setActiveTab(activeTab)
  }

  return (
  <RoleContext.Consumer>
    { roleCtx => (


    <Page { ...props} breadcrumb = {getBreadcrumb()} commandBar={getCommandBar()} isLoading={isLoading} isSaving={isSaving}>
      <Button onClick={()=> {console.log('lds: ', lds);}}>Show LDS State</Button>
      <Button onClick={()=> {console.log('ldsInfo: ', ldsInfo);}}>Show LDS INFO State</Button>

      <Grid container>
          <Grid size={6}>
              <Paper sx={{padding: '.5em 1em'}}>
                {lds.id && <Grid container>
                  <Grid size={3}>LDS Version:</Grid>
                  <Grid size={9}>{lds.version?.major ?? '0'}.{lds.version?.minor ?? '0'}.{lds.version?.revision ?? '0'}</Grid>
                </Grid>}
                <Grid container>
                  <Grid size={3}>Salesforce Product ID:</Grid>
                  <Grid size={9}>{id}</Grid>
                  <Grid size={3}>Product Name:</Grid>
                  <Grid size={9}>{lds.product?.name}</Grid>
                </Grid>
                <br/>
                <Grid container>
                  <Grid size={3}>Formula Name:</Grid>
                  <Grid size={9}>{lds.formula?.formulaName}</Grid>
                  <Grid size={3}>BBN:</Grid>
                  <Grid size={9}>{lds.formula?.bbn}</Grid>
                </Grid>
              </Paper>

              <Panel title="Layout Options" sx={{margin:'1em 0'}}>
                  <Grid container rowSpacing={1}>
                      <Grid container size={6}>
                          <Grid size={6}>Change Status:</Grid>
                          <Grid size={6}>
                            <Select id="status" size="small" value={lds.status} onChange={(event) => {handleSelectChange('status', event, lds, setLds, setDirty)}}>
                              <MenuItem value="draft">Draft</MenuItem>
                              <MenuItem value="review">Under Review</MenuItem>
                              <MenuItem value="approved">Approved</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      <Grid container size={6}>
                          <Grid size={6}>Layout:</Grid>
                          <Grid size={6}>
                            <Select id="layout" size="small" value={lds.layout} onChange={(event) => {handleSelectChange('layout', event, lds, setLds, setDirty)}}>
                              <MenuItem value="">Select One</MenuItem>
                              <MenuItem value="vertical">Vertical</MenuItem>
                              <MenuItem value="tabular">Tabular</MenuItem>
                              <MenuItem value="linear">Linear</MenuItem>
                              <MenuItem value="dual">Dual Column</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      <Grid container size={6}>
                          <Grid size={6}>Simplified/Standard:</Grid>
                          <Grid size={6}>
                            <Select id="isSimplified" size="small" value={lds.isSimplified} onChange={(event) => {handleSelectChange('isSimplified', event, lds, setLdsIsSimplified, setDirty)}}>
                              <MenuItem value={false}>Standard</MenuItem>
                              <MenuItem value={true}>Simplified</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      <Grid container size={6}>
                          <Grid size={6}>Abbreviated/Non-Abbreviated:</Grid>
                          <Grid size={6}>
                            <Select id="isAbbreviated" size="small" value={lds.isAbbreviated} onChange={(event) => {handleSelectChange('isAbbreviated', event, lds, setLds, setDirty)}}>
                              <MenuItem value={false}>Non-Abbreviated</MenuItem>
                              <MenuItem value={true}>Abbreviated</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                      {lds.isSimplified && 
                        <>
                          <Grid container size={6}>
                            <Grid size={6}>Include Claim on Sugar?</Grid> 
                            <Grid size={6}>
                              <Checkbox id="isSimplifiedWithSugar" size="small" value={lds.isSimplifiedWithSugar} onChange={(event) => {handleCheckboxChange('isSimplifiedWithSugar', event, lds, setLds, setDirty)}} />
                            </Grid>
                          </Grid>
                          <Grid container size={6}></Grid>
                        </>
                      }
                      <Grid container size={6}>
                          <Grid size={6}>Single-Serve/Multi-Serve:</Grid>
                          <Grid size={6}>
                            <Select id="serve" size="small" value={lds.serve} onChange={(event) => {handleSelectChange('serve', event, lds, setLds, setDirty)}}>
                              <MenuItem value="single">Single-Serve</MenuItem>
                              <MenuItem value="multi">Multi-Serve</MenuItem>
                            </Select>
                          </Grid>
                      </Grid>
                  </Grid>
              </Panel>
              <Panel>
                <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
                  <Tab label="Nfp" variant="contained" />
                  <Tab label="LDS Info" />
                  {/* <Tab label="Collaborators" />
                  <Tab label="History" /> */}
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                  {lds && <Grid container spacing={2}>
                    <Grid container size={12} >
                      {lds.serve === 'multi' && <Grid size={12}>
                        <LDSInput label="Servings per Container" showToggles={ false } labelSize={3.4} id="servingsPerContainer" fullWidth value={lds.nfp1?.servingsPerContainer ?? ''} />
                      </Grid>}
                      <Grid size={12}>
                        <LDSInput label="Serving Size" showToggles={ false } labelSize={3.4} id="servingSize" fullWidth value={lds.product?.servingSize ?? ''} />
                      </Grid>
                      <Grid size={12}>
                        <HorizontalRule thickness={8} />
                      </Grid>

                      {nfp1 && [...Object.keys(nfp1)].map((id) => (
                        <>
                          <Grid size={6}>
                            {nfp1[id].showAmount && <LDSInput nfp={nfp1[id]} label={nfp1[id].label} showToggles={ !nfp1[id].isMandatory } id={`${id}.value`} fullWidth value={nfp1[id].value} checkState={nfp1[id].amountState} onChange={(event) => { const { target } = event; handleFieldChange(id, target.value, nfp1[id].value, setNfp1AmountState, setDirty)}} />}
                            {!nfp1[id].showAmount && nfp1[id].name}
                          </Grid>
                          <Grid size={6}>
                            {nfp1[id].showPercent && <LDSInput nfp={nfp1[id]} label="%DV" showCitation={nfp1[id].isMicroNutrient} showToggles={ !nfp1[id].isMandatory || nfp1[id].isMicroNutrient } id={`${id}_percent`} fullWidth value={nfp1[id].percent} checkState={nfp1[id].percentState} onChange={(event) => { const { target } = event; handleFieldChange(id, target.value, nfp1[id].percent, setNfp1PercentState, setDirty)}} />}
                          </Grid>
                          { id === '84a8709a-0001-0000-ebf9-90cea7d9d44f' &&
                            <Grid size={12}>
                             <HorizontalRule thickness={4} />
                            </Grid>
                          }
                        </>
                      ))}
                    </Grid>
                  </Grid>}
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  {ldsInfo && <Grid container spacing={2}>
                    <Grid container size={12} sx={{padding: '.5em'}}>
                      <Grid size={4}>
                        <Typography>Statement of Identity</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.statementOfIdentity}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Net Quantity of Contents</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.netQuantityOfContentsFreeForm}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Front-of-Pack Declaration</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.frontOfPackDeclarationFreeForm}</Typography>
                      </Grid>
                    </Grid>

                    <Grid container size={12} sx={{padding: '.5em', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc'}}>
                      <Grid size={4} >
                        <Typography>Juice Content</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.juiceContent}</Typography>
                        {ldsInfo.juiceContentFinalValue  && <Typography>(Juice Content Label Claim): {ldsInfo.juiceContentFinalValue}</Typography>}
                      </Grid>
                    
                      <Grid size={4}>
                        <Typography>Ingredient Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.ingredients}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Manufacturer&apos;s Information</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.finalManufacturersStatement}</Typography>
                      </Grid>
                    </Grid>

                    <Grid container size={12} sx={{padding: '.5em'}}>
                      <Grid size={4}>
                        <Typography>Country of Origin Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.countryOfOriginStatement}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Allergen Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.allergensNotes}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>BioEngineered Disclosure Statement</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.bioengineeredDisclosureStatement}</Typography>
                      </Grid>
                    </Grid>
                    
                    <Grid container size={12} sx={{padding: '.5em', borderTop: '1px dashed #ccc', borderBottom: '1px dashed #ccc'}}>
                      <Grid size={4}>
                        <Typography>Consumer Inquiries</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.consumerInquiriesFreeForm}</Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Claims and Qualifying Language</Typography>
                      </Grid>
                      <Grid size={8}>
                        <div> {ldsInfo.nutrientContentHealthClaimFreeForm ? ('(Nutrient Content Claim): ' + ldsInfo.nutrientContentHealthClaimFreeForm).replace(/(<([^>]+)>)/gi, "").split(/[\r\n]/).map((item, idx) => {
                          return (
                            <span key={`nutrientContentHealthClaimFreeForm-${idx}`}>
                              {he.decode(item)}
                              <br/>
                            </span>
                          )
                        }) : ''} </div>
                        <div> {ldsInfo.otherInformationClaimsFreeForm ? ('(Other Claims): ' + ldsInfo.otherInformationClaimsFreeForm).replace(/(<([^>]+)>)/gi, "").split(/[\r\n]/).map((item, idx) => {
                          return (
                            <span key={`otherInformationClaimsFreeForm-${idx}`}>
                              {he.decode(item)}
                              <br/>
                            </span>
                          )
                        }) : ''} </div>
                      </Grid>

                      <Grid size={4}>
                        <Typography>Kosher</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.kosherFreeForm}</Typography>
                      </Grid>
                    </Grid>
                    
                    <Grid container size={12} sx={{padding: '.5em'}}>
                      <Grid size={4}>
                        <Typography>Other Label Information (i.e. Blend Statement; PKU Warning Statement; Caffeine info.; Organic info.; Reconstitution instructions; Storage/use instructions;
                          NOTE: Please make sure that the 1-800-telephone number for consumer inquiries is listed on the label.)</Typography>
                      </Grid>
                      <Grid size={8}>
                          {ldsInfo.blendStatement && <Typography>(Blend Statement): {ldsInfo.blendStatement}</Typography>}
                          {ldsInfo.caffeineQuantitativeClaimFreeForm && <Typography>(Caffeine Information): {ldsInfo.caffeineQuantitativeClaimFreeForm}</Typography>}
                          {ldsInfo.organicInformation && <Typography>(Organic Information): {ldsInfo.organicInformation}</Typography>}
                          {ldsInfo.reconstitutionInstructions && <Typography>(Reconstitution Instructions): {ldsInfo.reconstitutionInstructions}</Typography>}
                          {ldsInfo.storageUseInstructions && <Typography>(Storage Use Instructions): {ldsInfo.storageUseInstructions}</Typography>}
                          {ldsInfo.pkuWarningStatement && <Typography>(PKU Warning Statement): {ldsInfo.pkuWarningStatement}</Typography>}
                          {ldsInfo.consumerInquiriesFreeForm && <Typography>(Consumer Inquiries Freeform): {ldsInfo.consumerInquiriesFreeForm}</Typography>}
                          {ldsInfo.bioengineeredDisclosureStatement && <Typography>(BioEngineered Disclosure Statement): {ldsInfo.bioengineeredDisclosureStatement}</Typography>}
                          {ldsInfo.otherInformation && <Typography>(Blend Statement): {ldsInfo.otherInformation}</Typography>}
                      </Grid>

                      <Grid size={4}>
                        <Typography>Label Code</Typography>
                      </Grid>
                      <Grid size={8}>
                        <Typography>{ldsInfo.labelCode}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  }
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                  Content for Collaborators
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                  Content for History
                </TabPanel>
              </Panel>
          </Grid>
          <Grid size={6}>
            <div style={{ position: "fixed", top: "106px", left: '50%', transform: 'translateX(170px)',  height:"100vh" }}>
              <Box sx={{width: '100% - 270px'}}>
                {lds.layout === 'vertical' &&
                <NfpVerticalView 
                  nfp={nfp1 ?? {}} 
                  lds={lds ?? {}}
                  significantSourceText={significantSourceText} 
                />}
                {lds.layout === 'tabular' &&
                <NfpTabularView 
                  nfp={nfp1 ?? {}} 
                  lds={lds ?? {}}
                  significantSourceText={significantSourceText}                   
                />}
                {lds.layout === 'linear' &&
                <NfpLinearView 
                  nfp={nfp1 ?? {}} 
                  lds={lds ?? {}}
                  significantSourceText={significantSourceText} 
                />}
                {lds.layout === 'dual' &&
                <NfpDualColumnView 
                  nfp1={nfp1 ?? {}} 
                  nfp2={nfp2 ?? {}} 
                  lds={lds ?? {}}
                  significantSourceText={significantSourceText} 
                />}
              </Box>
            </div>
          </Grid>
      </Grid>

      <Dialog open={showCancelModal}>
        <DialogTitle>Unsaved Changes</DialogTitle>

        <DialogContent>
          <p>You have unsaved changes. Are you sure you want to cancel?</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {
            navigate(-1, 
              {
                message: {
                  text: 'Changes were discarded...',
                  type: 'info',
                  duration: 2000
                }
              }
            );
          }}>Discard Changes</Button>
          <Button onClick={() => {
            setShowCancelModal(false)
          }}>Continue Working</Button>
        </DialogActions>
      </Dialog>
    </Page>
    )}
  </RoleContext.Consumer>
)}