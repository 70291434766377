
import { default as React, useState, useEffect, useRef } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import CancelIcon from '@mui/icons-material/Cancel'
import { useIsMounted } from '../utils/ReactUtils'
import { gsap } from "gsap"


export default function AnimatedAlert({message, onComplete}) {
  const isMounted = useIsMounted()
  const [isVisible, setIsVisible] = useState(false)
  const [messageAnimationTimer, setMessageAnimationTimer] = useState()
  const container = useRef(null)


  const getIcon = (style) => {
    const iconMap = {
      success: <CheckCircleIcon />,
      info: <InfoIcon />,
      error: <ErrorIcon />,
      warn: <WarningIcon />,
      warning: <WarningIcon />
    }
    return iconMap[style] || <InfoIcon />
  }

  const hide = (onComplete) => {
    if (isVisible) {
      let fromOptions = { y: 0, opacity: 1 }
      let toOptions = { y: -25, opacity: 0, duration: .3 }
      if (onComplete) {
        toOptions.onComplete = () => {
          onComplete()
          setIsVisible(false)
        }
      } else {
        toOptions.onComplete = () => {
          setIsVisible(false)
        }
      }
      gsap.fromTo(container.current, fromOptions, toOptions)
    }
  }

  const show = (onComplete) => {
    if (!isVisible) {
      let fromOptions = { y: -25, opacity: 0 }
      let toOptions = { y: 0, opacity: 1, duration: .3 }
      if (onComplete) {
        toOptions.onComplete = onComplete
      }
      gsap.fromTo(container.current, fromOptions, toOptions)
      setIsVisible(true)
    }
  }

  useEffect(() => {
    if (isVisible && message.duration) {
      clearTimeout(messageAnimationTimer)
      setMessageAnimationTimer(setTimeout(() => { hide(onComplete) }, message.duration))
    }
  }, [isVisible, message.duration])

  useEffect(() => {
    // console.log('AnimatedAlert.useEffect called')
    if (isMounted() && message) {
      // console.log('AnimatedAlert.useEffect, showing message: ', props.message)
      show()
    }
  }, [isMounted, message])

  useEffect(() => {
    console.log('message duration changed', message.duration)
  }, [message.duration])

  return (
    <div ref={container} className="tis-alert-container">
      {(message.duration === undefined || message.duration === 0 || message.showClose) && isVisible &&
        <CancelIcon sx={{}} className={`tis-alert-close ${message.style}`} onClick={() => { hide(onComplete) }} />
      }
      {isVisible &&
        <div className={`tis-alert tis-alert-${message.style}`}>
          <span>{getIcon(message.style)} {message.text}
          </span>
          {message.jsx ? <div><br />{message.jsx}</div> : undefined}
        </div>
      }
    </div>
  )
}
