import { Route, Routes } from 'react-router-dom'
import LogoutPage from '../auth/LogoutPage'
import ListProductsPage from '../products/ListProductsPage'
import ProductFormPage from '../products/ProductFormPage'
import CanadianLdsEntryPage from '../labelDeclarationSheets/ca/CanadianLdsEntryPage'
// import CanadianLdsSupplementedEntryPage from '../labelDeclarationSheets/ca/CanadianLdsSupplementedEntryPage'
import LdsEntryPage from '../labelDeclarationSheets/us/LdsEntryPage'
import ListGenesisFormulasPage from '../genesis/ListGenesisFormulasPage'
import ListActiveLdsPage from '../labelDeclarationSheets/ListActiveLdsPage'
import GenesisFormulaViewerPage from '../genesis/GenesisFormulaViewerPage'
import ListUsersPage from '../users/ListUsersPage'
import UserFormPage from '../users/UserFormPage'
import HomePage from '../home/HomePage'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import NutritionCalculatorFormPage from '../nutritionCalculator/NutritionCalculatorFormPage'
// import { FeatureFlags } from '../config/config'

export default function Body(props = {}) {
  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <div className="content-wrapper">
          <section className="content body">
            <div className="row">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/logout" element={<LogoutPage />} />
                {
                  roleCtx.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <>
                    <Route path="/users/list" element={<ListUsersPage />} />
                    <Route path="/users/edit/:koId" element={<UserFormPage />} />
                  </>
                }
                <Route path="/products" element={<ListProductsPage />} />
                <Route path="/products/:id" element={<ProductFormPage />} />
                <Route path="/label-declaration-sheets" element={<ListActiveLdsPage />} />
                <Route path="/label-declaration-sheets/us/products/:id/entry" element={<LdsEntryPage />} />
                <Route path="/label-declaration-sheets/ca/products/:id/entry" element={<CanadianLdsEntryPage />} />
                {/* <Route path="/label-declaration-sheets/ca/products/:id/supplemented-entry" element={<CanadianLdsSupplementedEntryPage />} /> */}
                <Route path="/nutrition-calculator" element={<NutritionCalculatorFormPage />} />
                <Route path="/genesis" element={<ListGenesisFormulasPage />} />
                <Route path="/genesis/recipes/:id" element={<GenesisFormulaViewerPage />} />
              </Routes>
            </div>
          </section>
        </div>
      )}
    </RoleContext.Consumer>
  )
}
