export const getNamedNutrients = (nfp) => {
    if(nfp) {
        const calories = nfp['84a8709a-0000-0000-ebf9-90cea7d9d44f'] ?? {}
        const totalFat = nfp['84a8709a-0011-0000-ebf9-90cea7d9d44f'] ?? {}
        const satFat = nfp['84a8709a-0012-0000-ebf9-90cea7d9d44f'] ?? {}
        const transFat = nfp['84a8709a-0015-0000-ebf9-90cea7d9d44f'] ?? {}
        const polyFat = nfp['84a8709a-0014-0000-ebf9-90cea7d9d44f'] ?? {}
        const monoFat = nfp['84a8709a-0013-0000-ebf9-90cea7d9d44f'] ?? {}
        const cholesterol = nfp['84a8709a-0016-0000-ebf9-90cea7d9d44f'] ?? {}
        const sodium = nfp['84a8709a-003c-0000-ebf9-90cea7d9d44f'] ?? {}
        const carbs = nfp['84a8709a-0002-0000-ebf9-90cea7d9d44f'] ?? {}
        const dietaryFiber = nfp['84a8709a-0003-0000-ebf9-90cea7d9d44f'] ?? {}
        const totalSugars = nfp['84a8709a-0006-0000-ebf9-90cea7d9d44f'] ?? {}
        const addedSugars = nfp['84a8709a-0094-0000-ebf9-90cea7d9d44f'] ?? {}
        const sugarAlcohols = nfp['84a8709a-0074-0000-ebf9-90cea7d9d44f'] ?? {}
        const erythritol = nfp['84a8709a-00ce-0000-ebf9-90cea7d9d44f'] ?? {}
        const protein = nfp['84a8709a-0001-0000-ebf9-90cea7d9d44f'] ?? {}
        const vitaminD = nfp['84a8709a-0026-0000-ebf9-90cea7d9d44f'] ?? {}
        const calcium = nfp['84a8709a-002f-0000-ebf9-90cea7d9d44f'] ?? {}
        const iron = nfp['84a8709a-0035-0000-ebf9-90cea7d9d44f'] ?? {}
        const potassium = nfp['84a8709a-003a-0000-ebf9-90cea7d9d44f'] ?? {}

        return { 
            calories, totalFat, satFat, transFat, polyFat, monoFat, cholesterol, sodium, 
            carbs, dietaryFiber, totalSugars, addedSugars, sugarAlcohols, erythritol, protein,
            vitaminD, calcium, iron, potassium
        }
    } else {
        throw new Error('An nfp is required in order to extract the nutrients.')
    }
}

export const isSelected = (nutrientInfo) => {
    return nutrientInfo.amountState === 'Visible' || 
        nutrientInfo.percentState === 'Visible' || 
        nutrientInfo.amountState === 'Citation' || 
        nutrientInfo.percentState === 'Citation'
}