import React, { useEffect, useState } from "react"
import css from '../../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import DaggerIcon from '../../../img/DaggerIcon';
import { getLabel } from '../LdsEntryPage';
import { getNamedNutrients, isSelected } from '../../NfpUtils'

let css2 = {
  nutritionFactPanelTabular: {
    width: '514px'
  },
  av: {
    marginLeft: '1em',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  caloriesH3: {
    bottom: '25%',
    position: 'absolute',
    textAlign: 'right'
  },
  caloriesH4: {
    lineHeight: '1.75em',
    paddingTop: '1em'
  },
  column1: {
    width: '180px'
  },
  column2: {
    width: '320px'
  },
  columnNutrientAv: {
    margin: '0.5em 0 0 0.5em',
    paddingTop: '0.5em',
    fontSize: '10px'
  },
  columnNutrientDv: {
    margin: '0.5em 0 0 0.5em',
    paddingTop: '0.5em',
    fontSize: '10px',
    float: 'none'
  },
  dv: {
    float: 'right',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  h5: {
    fontSize: '12px',
    fontFamily: 'inherit',
    lineHeight: 1.1,
    verticalAlign: 'top',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    width: '100%',
    display: 'inline',
    verticalAlign: 'top'
  },  
  labels: { 
    height: '1.4em',
    display: 'flex',
    alignContent: 'space-between',
    alignItems: 'flex-end'
  },
  labelsH6: {
    verticalAlign: 'bottom',
    fontSize: '9px'
  },
  labelsH6Last: {
    textAlign: 'right'
  },
  nutrientName: {
    whiteSpace: 'nowrap',
    fontSize: '10px'
  },
  nutritionFacts: {
    paddingBottom: '.5em'
  },
  row: {
    height: null,
    clear: 'both'
  },
  servingSize: {
    marginTop: '10%',
    marginRight: 0,
    marginBottom: '10%',
    marginLeft: 0
  },
  significantSourceText: {
    display: 'block'
  }
}

export default function NfpTabularView({nfp = {}, lds = {}, serve, significantSourceText}) {
  const [showCitation, setShowCitation] = useState(false)

  useEffect(() => {
    
    let newShowCitation = [...Object.keys(nfp)].reduce((showCitation, id) => {
      return showCitation || nfp[id].percentState === 'Citation'
    }, false)
    setShowCitation(newShowCitation)
  }, [nfp])  

  lds.serve = lds.serve ?? 'single'
  lds.isAbbreviated = lds.isAbbreviated ?? false
  lds.isSimplified = lds.isSimplified ?? false

  const microNutrientNotSignificantSourceRules = {
    '84a8709a-0026-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showVitaminD
    '84a8709a-002f-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showCalcium
    '84a8709a-0035-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showIron
    '84a8709a-003a-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showPotassium
  }

  const microNutrients = [...Object.keys(nfp)].reduce((microNutrients, id) => {
    let n = nfp[id]
    if(n.isMicroNutrient && isSelected(n) && (!microNutrientNotSignificantSourceRules[id] || microNutrientNotSignificantSourceRules[id](n))) {
      microNutrients.push(n)
    }
    return microNutrients
  }, [])

  const renderMicroNutrientRow = (nutrient1, nutrient2) => {
    const getValue = (nutrient) => {
      return (nutrient) ? (nutrient.percentState === 'Citation' ? <DaggerIcon style={{fontSize: '9px', position:'relative', top:'-2px'}}/> : (
        <>
          <Box component="span" style={{ ...css.av, ...css2.av, ...css2.columnNutrientAv }}>{ nutrient.amountState === 'Visible' ? `${nutrient.value ?? '0'}${nutrient.uom}` : ''}</Box>
          <Box component="span" style={{ ...css.dv, ...css2.dv, ...css2.columnNutrientDv }}>{ nutrient.percentState === 'Visible' ? `${nutrient.percent ?? '0'}%` : ''}</Box>
        </>)) : <></>
    }
    let dot = nutrient2 ? <span style={{position: 'absolute', left: 0}}> • </span> : <></>

    return <div key={`nutrient${i}`} style={{width: '100%', height: '1.05em', clear: 'both'}}>
        <div style={{...css2.nutrientName, float: 'left', width: '50%'}}>
          <Box component="span">{getLabel(nutrient1, lds.isAbbreviated)} {getValue(nutrient1)}</Box>
        </div>
        <div style={{ ...css2.nutrientName, position: 'relative', textAlign: 'right', float: 'right', width: '50%'}}>
          <Box component="span">{dot} {nutrient2 && getLabel(nutrient2, lds.isAbbreviated)} {getValue(nutrient2)}</Box>
        </div>
    </div>
  }

  
  const { 
    calories, totalFat, satFat, transFat, polyFat, monoFat, cholesterol, sodium, 
    carbs, dietaryFiber, totalSugars, addedSugars, sugarAlcohols, erythritol, protein
  } = getNamedNutrients(nfp)

  let i = 0
  const showSaturatedFat = !lds.isSimplified || parseFloat(satFat.percent) >= 2
  const showTransFat = !lds.isSimplified || parseFloat(transFat.percent) >= 2
  const showPolyFat = !lds.isSimplified || parseFloat(polyFat.percent) >= 2 
  const showMonoFat = !lds.isSimplified || parseFloat(monoFat.percent) >= 2
  const showCholesterol = !lds.isSimplified || parseFloat(cholesterol.percent) >= 2
  const showDietaryFiber = !lds.isSimplified || parseFloat(dietaryFiber.percent) >= 2
  const showTotalSugars = !lds.isSimplified || parseFloat(totalSugars.value) >= 1
  const showAddedSugars = !lds.isSimplified || parseFloat(addedSugars.percent) >= 2

  return (
    <Box id="nutrition-fact-panel-tabular" style={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelTabular}}>
      <Grid container>
        <Grid item size={4.5} style={{display:'inline-block', width: '180px', paddingRight: '.5em'}}>
          <h2 style={Object.assign({}, css.h2, css2.nutritionFacts)}>Nutrition Facts</h2>
          <hr style={Object.assign({}, css.hr, css.hr1, css.mt0)}/>
          <Box style={Object.assign({}, css.row, css2.row, css2.servingSize)}>
            {serve === 'multi' &&
              <span>{nfp.servingsPerContainer} {'' + nfp.servingsPerContainer === '1' ? 'serving' : 'servings'} per container</span>
            }
            <Box style={Object.assign({}, css.colXs12, css.p0, css.m0)}><h4 style={css.h4}>{lds.isAbbreviated ? 'Serv.' : 'Serving'} Size</h4></Box>
            <Box style={Object.assign({}, css.colXs12, css.p0, css.m0)}><h4 style={{...css.h4, paddingLeft: '.5em'}}>{lds.product?.servingSize}</h4></Box>
            <Box style={css.clear}></Box>
          </Box>
          <hr style={Object.assign({}, css.hr, css.hr1, css.mt0)}/>
          <Box style={{...css.row, ...css2.row}}>
            <Box style={css.calories}>
              <h4 style={Object.assign({}, css.h4, css2.caloriesH4)}>Calories</h4>
              <h5 style={Object.assign({}, css2.h5, css.bold)}>Amount Per Serving</h5>
              <h3 style={Object.assign({}, css.h3, css2.caloriesH3)}>{calories.value ?? '0'}</h3>
            </Box>
            <Box style={css.clear}></Box>
          </Box>
        </Grid>
        <Grid item size={7.5} style={{display:'inline-block', width: '320px'}}>
          <Box style={Object.assign({}, css.row, css2.row, css2.labels)}>
            <h6 style={Object.assign({}, css.h6, css2.labelsH6)}>Amount/Serving</h6>
            <h6 style={Object.assign({}, css.h6, css2.labelsH6, css2.labelsH6Last)}>% Daily Value*</h6>
            <Box style={css.clear}></Box>
          </Box>
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          {totalFat.amountState === 'Visible' && <>
            <h5 style={Object.assign({}, css2.h5, css.bold)}>Total Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{`${totalFat.value ?? '0'}g`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${totalFat.percent ?? '0'}%`}</span>
          </>}
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          {!lds.isSimplified && 
          <>
            <Box style={css.indent1}>
              {showSaturatedFat && <Box>
                <h5 style={Object.assign({}, css2.h5, css.indent1Child)}>{lds.isAbbreviated ? 'Sat.' : 'Saturated'} Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{`${satFat.value ?? '0'}g`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${satFat.percent ?? '0'}%`}</span>
                <hr style={Object.assign({}, css.hr, css.hr1)}/>
              </Box>}
              {showTransFat && <Box>
                <h5 style={Object.assign({}, css2.h5, css.indent1Child)}><i>Trans</i> Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{`${transFat.value ?? '0'}g`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${transFat.percent ?? '0'}%`}</span>
                <hr style={Object.assign({}, css.hr, css.hr1)}/>
              </Box>}
              {showPolyFat && <Box>
                <h5 style={Object.assign({}, css2.h5, css.indent1Child)}>{lds.isAbbreviated ? 'Poly' : 'Polyunsaturated'} Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{`${polyFat.value ?? '0'}g`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${polyFat.percent ?? '0'}%`}</span>
                <hr style={Object.assign({}, css.hr, css.hr1)}/>
              </Box>}
              {showMonoFat && <Box>
                <h5 style={Object.assign({}, css2.h5, css.indent1Child)}>{lds.isAbbreviated ? 'Mono' : 'Monounsaturated'} Fat</h5><span style={Object.assign({}, css.av, css2.av)}>{`${monoFat.value ?? '0'}g`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${monoFat.percent ?? '0'}%`}</span>
                <hr style={Object.assign({}, css.hr, css.hr1)}/>
              </Box>}
            </Box>
            
            {showCholesterol && 
            <>
              <h5 style={Object.assign({}, css2.h5, css.bold)}>Cholesterol</h5>
              <span style={Object.assign({}, css.av, css2.av)}>{`${cholesterol.value ?? '0'}g`}</span>
              <span style={Object.assign({}, css.dv, css2.dv)}>{`${cholesterol.percent ?? '0'}%`}</span>
              <hr style={Object.assign({}, css.hr, css.hr1)}/>
            </>}
          </>}
          
          <h5 style={Object.assign({}, css2.h5, css.bold)}>Sodium</h5><span style={Object.assign({}, css.av, css2.av)}>{`${sodium.value ?? '0'}mg`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${sodium.percent ?? '0'}%`}</span>
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          <h5 style={Object.assign({}, css2.h5, css.bold)}>Total {lds.isAbbreviated ? 'Carb.' : 'Carbohydrate'}</h5><span style={Object.assign({}, css.av, css2.av)}>{`${carbs.value ?? '0'}g`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${carbs.percent ?? '0'}%`}</span>
          <hr style={Object.assign({}, css.hr, css.hr1)}/>
          
          <Box style={css.indent1}>
            {showDietaryFiber && <Box>
              <h5 style={Object.assign({}, css2.h5, css.indent1Child)}>{lds.isAbbreviated ? '' : 'Dietary'} Fiber</h5><span style={Object.assign({}, css.av, css2.av)}>{`${dietaryFiber.value ?? '0'}g`}</span><span style={Object.assign({}, css.dv, css2.dv)}>{`${dietaryFiber.percent ?? '0'}%`}</span>
            </Box>}
            {showDietaryFiber && showTotalSugars && <hr style={Object.assign({}, css.hr, css.hr1)}/>}
            {showTotalSugars && <Box><h5 style={Object.assign({}, css2.h5, css.indent1Child)}>Total Sugars</h5><span style={Object.assign({}, css.av, css2.av)}>{`${totalSugars.value ?? '0'}g`}</span></Box> }
            {showAddedSugars && 
              <Box style={{...css.indent1, ...css.indent1Child}}>
                {(addedSugars.amountState === 'Visible') && <Divider style={{ ...css.hr, ...css.hr1 }} /> }
                <Typography variant="h5" style={{ ...css2.h5 }}>{lds.isAbbreviated ? 'Incl.' : 'Includes'} {`${addedSugars.value ?? '0'}g`} Added Sugars</Typography>
                {addedSugars.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dv }}>{`${addedSugars.percent ?? '0'}%`}</Box>}
              </Box>
            }
            {isSelected(sugarAlcohols) && <Box style={css.indent1Child}>
              {(sugarAlcohols.amountState === 'Visible') && <Divider style={{ ...css.hr, ...css.hr1 }} /> }
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Sugar Alcohol</Typography>
              {sugarAlcohols.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{sugarAlcohols.value ?? '0'}g</Box>}
              {sugarAlcohols.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold}}>{sugarAlcohols.percent ?? '0'}%</Box>}
            </Box>}
            {isSelected(erythritol) && <Box style={css.indent1Child}>
              {(erythritol.amountState === 'Visible') && <Divider style={{ ...css.hr, ...css.hr1 }} /> }
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Erythritol</Typography>
              {erythritol.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{erythritol.value ?? '0'}g</Box>}
              {erythritol.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold}}>{erythritol.percent ?? '0'}%</Box>}
            </Box>}

          </Box>
          {(showDietaryFiber || showTotalSugars || showAddedSugars) && <hr style={Object.assign({}, css.hr, css.hr1)}/> }
          <h5 style={Object.assign({}, css2.h5, css.bold)}>Protein</h5><span style={Object.assign({}, css.av, css2.av)}>{`${protein.value ?? '0'}g`}</span>
          <hr style={Object.assign({}, css.hr, css.hr6)}/>
          {microNutrients?.length > 0 && <>
              <Grid container>
                {microNutrients?.length > 0 && <>
                    <Grid container>
                      {microNutrients.map((nutrient, idx, arr) => {
                        if(idx % 2 === 0) {
                          const nextNutrient = (idx + 1) < arr.length ? arr[idx + 1] : null
                          if(idx > 0) {
                            return <>
                              <Grid item size={12}><Divider style={{ ...css.hr, ...css.hr1}} /></Grid>
                              {renderMicroNutrientRow(nutrient, nextNutrient)}
                            </>
                          } else {
                            return renderMicroNutrientRow(nutrient, nextNutrient)
                          }
                        } else {
                          return <></>
                        }
                      })} 
                    </Grid>                                                          
                  </>
                } 
              </Grid>                                                          
            </>
          } 
          <br/>
          {!lds.isSimplified && 
            <Box component="span" style={{ ...css.fs6, ...css2.significantSourceText }}>* The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.</Box>
          }
          {showCitation && 
            <Box component="span" style={{ ...css.fs6, ...css2.significantSourceText }}><DaggerIcon style={{color: '#444', fontSize: '9px', position: 'relative', top:'-3px'}}/>Contains less than 2 percent of the Daily Value of these nutrients.</Box>
          }      
          {lds.isSimplified && significantSourceText && 
            <Box component="span" style={{ ...css.fs6, ...css2.significantSourceText }}>{significantSourceText}</Box>
          }
        </Grid>
      </Grid>
    </Box> 
  )
}
