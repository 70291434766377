import React, { useEffect, useState } from "react"
import css from '../../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import DaggerIcon from '../../../img/DaggerIcon';
import { getLabel } from '../LdsEntryPage';
import { getNamedNutrients, isSelected } from '../../NfpUtils'

let css2 = {
  h1: {
    fontSize: '32px',
    fontWeight: 900,
    lineHeight: 1,
    fontFamily: 'inherit',
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    display: 'inline'
  },
  h2: {
    fontSize: '20px',
    fontWeight: 900,
    fontFamily: 'inherit',
    lineHeight: 1.1,
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    display: 'inline'
  },
  label: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'inherit',
    lineHeight: 1.25,
    verticalAlign: 'bottom',
    color: '#000000',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    display: 'inline'
  },
  bold: {
    fontWeight: 900
  },
  
  nutritionFactPanelLinear: {
    minWidth: '510px',
    maxWidth: '510px'
  }
}

export default function NfpLinearView({lds = {}, nfp = {}, significantSourceText}) {
  const [showCitation, setShowCitation] = useState(false)

  useEffect(() => {
    let newShowCitation = [...Object.keys(nfp)].reduce((showCitation, id) => {
      return showCitation || nfp[id].percentState === 'Citation'
    }, false)
    setShowCitation(newShowCitation)
  }, [nfp]) 

  lds.serve = lds.serve ?? 'single'
  lds.isAbbreviated = lds.isAbbreviated ?? false
  lds.isSimplified = lds.isSimplified ?? false
  
  const microNutrients = [...Object.keys(nfp)].reduce((microNutrients, id) => {
    let n = nfp[id]
    if(n.isMicroNutrient && isSelected(n)) {
      microNutrients.push(n)
    }
    return microNutrients
  }, [])

  const microNutrientNotSignificantSourceRules = {
    '84a8709a-0026-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showVitaminD
    '84a8709a-002f-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showCalcium
    '84a8709a-0035-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showIron
    '84a8709a-003a-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showPotassium
  }
  const renderMicroNutrient = (nutrient) => {
    console.log('nu: ', nutrient)
    const getValue = (nutrient) => {
      return (nutrient) ? (nutrient.percentState === 'Citation' ? <DaggerIcon style={{fontSize: '9px', position:'relative', top:'-2px'}}/> : (
        <>
          <Box component="span" style={css2.label}>{ nutrient.amountState === 'Visible' ? `${nutrient.value ?? '0'}${nutrient.uom}` : ''}{ nutrient.percentState === 'Visible' ? ` (${nutrient.percent ?? '0'}% DV)` : ''}</Box>
        </>)) : <></>
    }
    if(!microNutrientNotSignificantSourceRules[nutrient.id] || microNutrientNotSignificantSourceRules[nutrient.id](nutrient)) {
      return <Box component="span" style={css2.label}>, {getLabel(nutrient, lds.isAbbreviated)} {getValue(nutrient)}</Box>
    }
  }

  let i = 0
  const { 
    calories, totalFat, satFat, transFat, polyFat, monoFat, cholesterol, sodium, 
    carbs, dietaryFiber, totalSugars, addedSugars, sugarAlcohols, erythritol, protein
  } = getNamedNutrients(nfp)

  //Show if > 2% DV
  const showSaturatedFat = !lds.isSimplified || parseFloat(satFat.percent) >= 2
  const showTransFat = !lds.isSimplified || parseFloat(transFat.percent) >= 2
  const showPolyFat = !lds.isSimplified || parseFloat(polyFat.percent) >= 2 
  const showMonoFat = !lds.isSimplified || parseFloat(monoFat.percent) >= 2
  const showCholesterol = !lds.isSimplified || parseFloat(cholesterol.percent) >= 2
  const showDietaryFiber = !lds.isSimplified || parseFloat(dietaryFiber.percent) >= 2
  const showTotalSugars = (!lds.isSimplified || lds.isSimplifiedWithSugar) || parseFloat(totalSugars.value) >= 1
  const showAddedSugars = (!lds.isSimplified || lds.isSimplifiedWithSugar) || parseFloat(addedSugars.percent) >= 2
  
  return (<>{nfp && 
    <Box id="nutrition-fact-panel-linear" style={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelLinear }}>
      <h1 style={{ ...css2.h1, marginRight: '12px' }}>Nutrition Facts</h1><h2 style={css2.h2}>{lds.isAbbreviated ? 'Serv.' : 'Serving'} Size: {lds.product?.servingSize},</h2>
      <Box component="span" style={css2.label}> Amount Per Serving: </Box>
      {lds.serve === 'multi' &&
        <Box component="span">{'' + lds.servingsPerContainer === '1' ? (lds.isAbbreviated ? 'Serv.' : 'Serving') : (lds.isAbbreviated ? 'Serv.' : 'Servings')} Per Container </Box>
      }
      
      <Box component="span" style={css2.h2}>Calories</Box> <Box component="span" style={css2.h1}>{calories.value ?? '0'}, </Box>
      <Box component="span" style={{ ...css2.label, ...css2.bold }}>Total Fat</Box> <Box component="span" style={css2.label}>{totalFat.value ?? '0'}g ({totalFat.percent ?? '0'}% DV), </Box>
      
      {showSaturatedFat && <>
        <Box component="span" style={css2.label}>{lds.isAbbreviated ? 'Sat.' : 'Saturated'} Fat</Box> <Box component="span" style={css2.label}>{satFat.value ?? '0'}g ({satFat.percent ?? '0'}% DV), </Box>
      </>}
      {showTransFat && <>
        <Box component="span" style={css2.label}><i>Trans</i> Fat</Box> <Box component="span" style={css2.label}>{transFat.value ?? '0'}g ({transFat.percent ?? '0'}% DV), </Box>
      </>}
      {showPolyFat && isSelected(polyFat) && <>
        <Box component="span" style={css2.label}>{lds.isAbbreviated ? 'Poly' : 'Polyunsaturated'} Fat</Box> <Box component="span" style={css2.label}>{polyFat.value ?? '0'}g ({polyFat.percent ?? '0'}% DV), </Box>
      </>}
      {showMonoFat && isSelected(monoFat) && <>
        <Box component="span" style={css2.label}>{lds.isAbbreviated ? 'Mono' : 'Monounsaturated'} Fat</Box> <Box component="span" style={css2.label}>{monoFat.value ?? '0'}g ({monoFat.percent ?? '0'}% DV), </Box>
      </>}
      {showCholesterol && <>
        <Box component="span" style={css2.label}>{lds.isAbbreviated ? 'Cholest.' : 'Cholesterol'}</Box> <Box component="span" style={css2.label}>{cholesterol.value ?? '0'}g ({cholesterol.percent ?? '0'}% DV), </Box>
      </>}
      <Box component="span" style={{ ...css2.label, ...css2.bold }}>Sodium</Box> <Box component="span" style={css2.label}>{sodium.value ?? '0'}g ({sodium.percent ?? '0'}% DV), </Box>
      <Box component="span" style={{ ...css2.label, ...css2.bold }}>Total {lds.isAbbreviated ? 'Carb.' : 'Carbohydrate'}</Box> <Box component="span" style={css2.label}>{carbs.value ?? '0'}g ({carbs.percent ?? '0'}% DV), </Box>

      {!lds.isSimplified &&
        <>
          {showDietaryFiber && <>
            <Box component="span" style={css2.label}>{lds.isAbbreviated ? '' : 'Dietary '}Fiber</Box> <Box component="span" style={css2.label}>{cholesterol.value ?? '0'}g ({cholesterol.percent ?? '0'}% DV), </Box>
          </>}
        </>
      }
      {(!lds.isSimplified || lds.isSimplifiedWithSugar) &&
        <>
          {showTotalSugars && <>
            <Box component="span" style={css2.label}>Total Sugars</Box> <Box component="span" style={css2.label}>{totalSugars.value ?? '0'}g </Box>
            {showAddedSugars && <>
              <Box component="span"><Box component="span" style={css2.label}>({lds.isAbbreviated ? 'Incl.' : 'Includes'} <Box component="span" style={css2.label}>{addedSugars.value ?? '0'}g</Box> Added Sugars, </Box><Box component="span" style={css2.label}>{addedSugars.percent ?? '0'}% DV)</Box></Box>
            </>}
            <Box component="span" style={css2.label}>, </Box>
          </>}
        </>
      }
      {isSelected(sugarAlcohols) && <>
          <Box component="span" style={css2.label}>Sugar Alcohols</Box> <Box component="span" style={css2.label}>{sugarAlcohols.value ?? '0'}g ({sugarAlcohols.percent ?? '0'}% DV), </Box>
        </>}
      {isSelected(erythritol) && <>
          <Box component="span" style={css2.label}>Erythritol</Box> <Box component="span" style={css2.label}>{erythritol.value ?? '0'}g ({erythritol.percent ?? '0'}% DV), </Box>
        </>}
      
      <Box component="span" style={{ ...css2.label, ...css2.bold }}>Protein</Box> <Box component="span" style={css2.label}>{protein.value ?? '0'}g</Box>

      {microNutrients?.length > 0 && 
        <>
          {microNutrients.map((nutrient) => {
            return renderMicroNutrient(nutrient)
          })} 
          <Box component="span" style={css2.label}>.</Box>
        </>
      }
      {!lds.isSimplified &&
        <Box component="span" style={css2.label}> * The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.</Box>
      }
      {lds.isSimplified && significantSourceText && <Box component="span" style={css2.label}> {significantSourceText}</Box>}
      {showCitation && <Box component="span" style={css2.label}> <DaggerIcon style={{color: '#444', fontSize: '9px', position: 'relative', top:'-3px'}}/>Contains less than 2 percent of the Daily Value of these nutrients.</Box>}
      
    </Box>
    }</>
  )
}