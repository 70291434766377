import React, { useEffect, useState } from "react"
import css from '../../NfpStyles'
import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material'
import DaggerIcon from '../../../img/DaggerIcon';
import { getLabel } from '../LdsEntryPage';
import { getNamedNutrients, isSelected } from '../../NfpUtils'

let css2 = {
  nutritionFactPanelVertical: {
    maxWidth: '342px'
  },
  av: {
    marginLeft: '1em',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  bold: {
    fontWeight: 900
  },
  calories: {
    height: '3em'
  },
  caloriesH2: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'right'
  },
  caloriesH3: {
    position: 'absolute', 
    bottom: 0,
    textAlign: 'left'
  },
  colXs6: {
    position: 'relative',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    float: 'left',
    width: '48%'
  },
  column1: {
    flexBasis: '100%',
    paddingLeft: 0
  },
  columnNutrientAv: {
    margin: '0.5em 0 0 0.5em',
    paddingTop: '0.5em',
    fontSize: '11px'
  },
  columnNutrientDv: {
    margin: '0.5em 0 0 0.5em',
    paddingTop: '0.5em',
    fontSize: '11px',
    float: 'none'
  },
  dv: {
    float: 'right',
    minHeight: '13px',
    verticalAlign: 'top'
  },
  dvBold: {
    float: 'right',
    minHeight: '13px',
    verticalAlign: 'top',
    fontWeight: 900
  },
  h5: {
    fontSize: '12px',
    fontFamily: 'inherit',
    lineHeight: 1.1,
    verticalAlign: 'top',
    color: '#000000',
    fontWeight: 400,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    width: '100%',
    display: 'inline',
    verticalAlign: 'top'
  },
  m003: {
    marginTop: '0px',
    marginRight: '0px',
    marginBottom: '3px',
    marginLeft: '0px'
  },  
  nutrientName: {
    whiteSpace: 'nowrap',
    fontSize: '11px'
  },
  servingSize: {
    lineHeight: 1.3
  },
  significantSourceText: {
    marginTop: '4px',
    display: 'block'
  }
}

export default function NfpVerticalView ({nfp = {}, lds = {}, significantSourceText}) {
  lds.serve = lds.serve ?? 'single'
  lds.isAbbreviated = lds.isAbbreviated ?? false
  lds.isSimplified = lds.isSimplified ?? false

  const microNutrientNotSignificantSourceRules = {
    '84a8709a-0026-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showVitaminD
    '84a8709a-002f-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showCalcium
    '84a8709a-0035-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showIron
    '84a8709a-003a-0000-ebf9-90cea7d9d44f': (nutrient) => !lds.isSimplified || parseFloat(nutrient.percent) >= 2 || nutrient.percentState === 'Citation', //showPotassium
  }

  const microNutrients = [...Object.keys(nfp)].reduce((microNutrients, id) => {
    let n = nfp[id]
    if(n.isMicroNutrient && isSelected(n) && (!microNutrientNotSignificantSourceRules[id] || microNutrientNotSignificantSourceRules[id](n))) {
      microNutrients.push(n)
    }
    return microNutrients
  }, [])

  let i = 0
  const { 
    calories, totalFat, satFat, transFat, polyFat, monoFat, cholesterol, sodium, 
    carbs, dietaryFiber, totalSugars, addedSugars, sugarAlcohols, erythritol, protein
  } = getNamedNutrients(nfp)

  //Show if > 2% DV
  const showSaturatedFat = !lds.isSimplified || parseFloat(satFat.percent) >= 2
  const showTransFat = !lds.isSimplified || parseFloat(transFat.percent) >= 2
  const showPolyFat = !lds.isSimplified || parseFloat(polyFat.percent) >= 2 
  const showMonoFat = !lds.isSimplified || parseFloat(monoFat.percent) >= 2
  const showCholesterol = !lds.isSimplified || parseFloat(cholesterol.percent) >= 2
  const showDietaryFiber = !lds.isSimplified || parseFloat(dietaryFiber.percent) >= 2
  const showTotalSugars = !lds.isSimplified || lds.isSimplifiedWithSugar || parseFloat(totalSugars.value) >= 1
  const showAddedSugars = !lds.isSimplified || lds.isSimplifiedWithSugar || parseFloat(addedSugars.percent) >= 2

  const [showCitation, setShowCitation] = useState(false)

  useEffect(() => {
    
    let newShowCitation = [...Object.keys(nfp)].reduce((showCitation, id) => {
      return showCitation || nfp[id].percentState === 'Citation'
    }, false)
    setShowCitation(newShowCitation)
  }, [nfp])  



  const renderMicroNutrientRow = (nutrient1, nutrient2) => {
    const getValue = (nutrient) => {
      return (nutrient) ? (nutrient.percentState === 'Citation' ? <DaggerIcon style={{fontSize: '9px', position:'relative', top:'-2px'}}/> : (
        <>
          <Box component="span" style={{ ...css.av, ...css2.av, ...css2.columnNutrientAv }}>{ nutrient.amountState === 'Visible' ? `${nutrient.value ?? '0'}${nutrient.uom}` : ''}</Box>
          <Box component="span" style={{ ...css.dv, ...css2.dv, ...css2.columnNutrientDv }}>{ nutrient.percentState === 'Visible' ? `${nutrient.percent ?? '0'}%` : ''}</Box>
        </>)) : <></>
    }
    let dot = nutrient2 ? <span style={{position: 'absolute', left: 0}}> • </span> : <></>

    return <div key={`nutrient${i}`} style={{width: '100%', height: '1.05em', clear: 'both'}}>
        <div style={{...css2.nutrientName, float: 'left', width: '50%'}}>
          <Box component="span">{getLabel(nutrient1, lds.isAbbreviated)} {getValue(nutrient1)}</Box>
        </div>
        <div style={{ ...css2.nutrientName, position: 'relative', textAlign: 'right', float: 'right', width: '50%'}}>
          <Box component="span">{dot} {nutrient2 && getLabel(nutrient2, lds.isAbbreviated)} {getValue(nutrient2)}</Box>
        </div>
    </div>
  }

  return (<>{nfp && 
    <Box id="nutrition-fact-panel-vertical" style={{ ...css.nutritionFactPanel, ...css2.nutritionFactPanelVertical }}>
      <Typography variant="h1" style={css.h1}>Nutrition Facts</Typography>
      <Divider style={{...css.hr, ...css.hr1, ...css.mt0}} />
      {lds.serve === 'multi' &&
        <Box component="span">{lds.servingsPerContainer} {'' + lds.servingsPerContainer === '1' ? 'serving' : 'servings'} per container</Box>
      }

      <Box style={{ ...css.p0, ...css.m0}}>
        <Grid container>
          <Grid item size={6}>
            <Typography variant="h4" style={{ ...css.h4, ...css2.servingSize}}>{lds.isAbbreviated ? 'Serv.' : 'Serving'} Size</Typography>
          </Grid>
          <Grid item size={6}>
            <Typography variant="h4" style={{ ...css.h4, ...css.dvBold }}>{lds.product?.servingSize}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ ...css.hr, ...css.hr6, ...css.mb0}} />
        
      <Grid container>
        <Grid item size={12}>
          {calories.amountState === 'Visible' && <>
            <Box style={{ ...css.calories, ...css2.calories }}>
              <Typography variant="h3" style={{ ...css.h3, ...css2.caloriesH3 }}>Calories</Typography>
              <Box style={{ ...css.fs6, ...css2.bold }}>Amount Per Serving</Box>
              <Typography variant="h2" style={{ ...css.h2, ...css2.caloriesH2 }}>{calories.value}</Typography>
            </Box>
            <Divider style={{ ...css.hr, ...css.hr4 }}/>
          </>}
          
          <Typography variant="h6" style={{ ...css.h6, ...css.dv }}>% Daily Value*</Typography>
          <Divider style={{ ...css.hr, ...css.hr1 }}/>

          {(totalFat.amountState === 'Visible' || totalFat.percentState === 'Visible') && 
          <>
            <Typography variant="h5" style={{ ...css2.h5, ...css.bold}}>Total Fat</Typography>
            {totalFat.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${totalFat.value ?? '0'}g`}</Box>}
            {totalFat.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${totalFat.percent ?? '0'}%`}</Box>}
            <Divider style={{ ...css.hr, ...css.hr1}} />
          </>}
          {!lds.isSimplified && 
          <>
            <Box style={css.indent1}>
              {showSaturatedFat && <>
                <Typography variant="h5" style={{ ...css2.h5, ...css.indent1Child }}>{lds.isAbbreviated ? 'Sat.' : 'Saturated'} Fat</Typography>
                {satFat.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${satFat.value ?? '0'}g`}</Box>}
                {satFat.percentState === 'Visible' && <Box component="span" style={{...css.dv, ...css2.dvBold}}>{`${satFat.percent ?? '0'}%`}</Box>}
                <Divider style={{ ...css.hr, ...css.hr1}} />
              </>}
              {showTransFat && <>
                <Typography variant="h5" style={{ ...css2.h5, ...css.indent1Child }}><i>Trans</i> Fat</Typography>
                {transFat.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${transFat.value ?? '0'}g`}</Box>}
                {transFat.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${transFat.percent ?? '0'}%`}</Box>}
                <Divider style={{ ...css.hr, ...css.hr1}} />
              </>}
              {showPolyFat && isSelected(polyFat) && <>
                <Typography variant="h5" style={{ ...css2.h5, ...css.indent1Child }}>{lds.isAbbreviated ? 'Poly' : 'Polyunsaturated'} Fat</Typography>
                {polyFat.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${polyFat.value ?? '0'}g`}</Box>}
                {polyFat.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${polyFat.percent ?? '0'}%`}</Box>}
                <Divider style={{ ...css.hr, ...css.hr1}} />
              </>}
              {showMonoFat  && isSelected(monoFat) && <>
                <Typography variant="h5" style={{ ...css2.h5, ...css.indent1Child }}>{lds.isAbbreviated ? 'Mono' : 'Monounsaturated'} Fat</Typography>
                {monoFat.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${monoFat.value ?? '0'}g`}</Box>}
                {monoFat.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${monoFat.percent ?? '0'}%`}</Box>}
                <Divider style={{ ...css.hr, ...css.hr1}} />
              </>}
            </Box>
            {showCholesterol && <>
              <Typography variant="h5" style={{ ...css2.h5, ...css.bold}}>{lds.isAbbreviated ? 'Cholest.' : 'Cholesterol'}</Typography>
              {cholesterol.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${cholesterol.value ?? '0'}g`}</Box>}
              {cholesterol.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${cholesterol.percent ?? '0'}%`}</Box>}
              <Divider style={{ ...css.hr, ...css.hr1}} />
            </>}
          </>}
         
            <Typography variant="h5" style={{ ...css2.h5, ...css.bold}}>Sodium</Typography>
            {sodium.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${sodium.value ?? '0'}mg`}</Box>}
            {sodium.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${sodium.percent ?? '0'}%`}</Box>}
            <Divider style={{ ...css.hr, ...css.hr1}} />
         
         
            <Typography variant="h5" style={{ ...css2.h5, ...css.bold}}>Total {lds.isAbbreviated ? 'Carb.' : 'Carbohydrate'}</Typography>
            {carbs.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${carbs.value ?? '0'}g`}</Box>}
            {carbs.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${carbs.percent ?? '0'}%`}</Box>}
            <Divider style={{ ...css.hr, ...css.hr1}} />
         
          <Box style={css.indent1}>
            {showDietaryFiber && <>
              <Typography variant="h5" style={{ ...css2.h5, ...css.indent1Child }}>{lds.isAbbreviated ? '' : 'Dietary '}Fiber</Typography>
              {dietaryFiber.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{`${dietaryFiber.value ?? '0'}g`}</Box>}
              {dietaryFiber.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${dietaryFiber.percent ?? '0'}%`}</Box>}
              {showTotalSugars && <Divider style={{ ...css.hr, ...css.hr1}} />}
            </>}

            {showTotalSugars && <>
              <Typography variant="h5" style={{ ...css2.h5, ...css.indent1Child}}>Total Sugars</Typography>
              <Box component="span" style={{ ...css.av, ...css2.av }}>{`${totalSugars.value ?? '0'}g`}</Box>
            </>}
            {showAddedSugars && 
            <Box style={{...css.indent1, ...css.indent1Child}}>
              {(addedSugars.amountState === 'Visible') && <Divider style={{ ...css.hr, ...css.hr1 }} /> }
              <Typography variant="h5" style={{ ...css2.h5 }}>{lds.isAbbreviated ? 'Incl.' : 'Includes'} {`${addedSugars.value ?? '0'}g`} Added Sugars</Typography>
              {addedSugars.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold }}>{`${addedSugars.percent ?? '0'}%`}</Box>}
            </Box>}
            {isSelected(sugarAlcohols) && <Box style={css.indent1Child}>
              {(sugarAlcohols.amountState === 'Visible') && <Divider style={{ ...css.hr, ...css.hr1 }} /> }
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Sugar Alcohol</Typography>
              {sugarAlcohols.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{sugarAlcohols.value ?? '0'}g</Box>}
              {sugarAlcohols.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold}}>{sugarAlcohols.percent ?? '0'}%</Box>}
            </Box>}
            {isSelected(erythritol) && <Box style={css.indent1Child}>
              {(erythritol.amountState === 'Visible') && <Divider style={{ ...css.hr, ...css.hr1 }} /> }
              <Typography variant="h5" style={{ ...css2.h5, ...css2.indent1Child, display:'inline' }}>Erythritol</Typography>
              {erythritol.amountState === 'Visible' && <Box component="span" style={{ ...css.av, ...css2.av }}>{erythritol.value ?? '0'}g</Box>}
              {erythritol.percentState === 'Visible' && <Box component="span" style={{ ...css.dv, ...css2.dvBold}}>{erythritol.percent ?? '0'}%</Box>}
            </Box>}
          </Box>
          
          {(showDietaryFiber || showTotalSugars || showAddedSugars) && <Divider style={{ ...css.hr, ...css.hr1}} /> }
          <Typography variant="h5" style={{ ...css2.h5, ...css.bold}}>Protein</Typography>
          <Box component="span" style={{ ...css.av, ...css2.av }}>{`${protein.value ?? '0'}g`}</Box>
          
          <Divider style={{ ...css.hr, ...css.hr6 }} />

          {microNutrients?.length > 0 && <>
              <Grid container>
                {microNutrients.map((nutrient, idx, arr) => {
                  if(idx % 2 === 0) {
                    const nextNutrient = (idx + 1) < arr.length ? arr[idx + 1] : null
                    if(idx > 0) {
                      return <>
                        <Grid item size={12}><Divider style={{ ...css.hr, ...css.hr1}} /></Grid>
                        {renderMicroNutrientRow(nutrient, nextNutrient)}
                      </>
                    } else {
                      return renderMicroNutrientRow(nutrient, nextNutrient)
                    }
                  } else {
                    return <></>
                  }
                })} 
              </Grid>                                                          
            </>
          }
        </Grid>
      </Grid>
      <Divider style={{ ...css.hr, ...css.hr4 }} />
      {!lds.isSimplified && 
        <Box component="span" style={{ ...css.fs6, ...css2.significantSourceText }}>* The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.</Box>
      }
      {showCitation && 
        <Box component="span" style={{ ...css.fs6, ...css2.significantSourceText }}><DaggerIcon style={{color: '#444', fontSize: '9px', position: 'relative', top:'-3px'}}/>Contains less than 2 percent of the Daily Value of these nutrients.</Box>
      }      
      {lds.isSimplified && significantSourceText && 
        <Box component="span" style={{ ...css.fs6, ...css2.significantSourceText }}>{significantSourceText}</Box>
      }
    </Box>}
  </>)
}

